import { useContext } from 'react';

import { AppContext } from './AppContext';
import { AppContextType } from './AppContext.typings';

function useAppContext(): AppContextType {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('use only context only provider');
    }

    return context;
}

export default useAppContext;
