import { filterFalsyValues } from '../utils/common';
import { fetcher } from '../utils/fetcher';
import { SubscribeRecurlyError, SubscribeRecurlySecure3d, SubscribeRecurlySuccess } from '../model/typings/recurly';
import { API_Prefix, API_ULRS } from '../constants/url';
import { LocationConfig } from '../providers/LoggerProvider/LoggerProvider.typings';
import { SubscriptionListType } from '../model/typings/subscriptionListType';
import { Empty } from '../model/typings/common';

export async function createSubscriptionNoUser({
    url,
    email,
    plan_id,
    token_id,
    attribution,
}: any): Promise<SubscribeRecurlySuccess | SubscribeRecurlyError | SubscribeRecurlySecure3d> {
    const newAttribution = filterFalsyValues(attribution ?? {});

    return fetcher(`${url}/create-subscription-no-user`, {
        method: 'POST',
        body: JSON.stringify({
            plan_id,
            token_id,
            email,
            attribution: newAttribution ?? {},
        }),

        headers: {
            'Content-Type': 'text/plain',
        },
    });
}

export async function createSubscriptionRecurly({
    url,
    plan_id,
    subscription_id,
    email,
    firebase_id,
    apple_token,
    attribution,
}: any): Promise<SubscribeRecurlySuccess | SubscribeRecurlyError | SubscribeRecurlySecure3d> {
    const newAttribution = filterFalsyValues(attribution ?? {});

    return fetcher(`${url}/subscription-finalize`, {
        method: 'POST',
        body: JSON.stringify({
            plan_id,
            subscription_id,
            email,
            firebase_id,
            apple_token,
            attribution: newAttribution,
        }),
        headers: {
            'Content-Type': 'text/plain',
        },
    });
}

export const activateSubscriptionRecurly = async ({ url, account_code, email, firebase_id, apple_token }: any) =>
    fetcher(`${url}/subscription-activate`, {
        method: 'POST',
        body: JSON.stringify({
            account_code,
            email,
            firebase_id,
            apple_token,
        }),
        headers: {
            'Content-Type': 'text/plain',
        },
    });

export const getLocationData = async (): Promise<LocationConfig> =>
    fetcher(API_ULRS.LocationIP, {
        method: 'GET',
    });

export const getPaymentConfig = async ({ appUrl, searchString }: any) =>
    fetcher(`${appUrl.url}/api/quiz/config`, {
        method: 'POST',
        body: JSON.stringify({
            url: `${appUrl.basePath}` + searchString,
        }),
        headers: {
            'Content-Type': 'text/plain',
        },
    });

/**
 * Track appycnt events
 * @param event: string --> any string
 * @param eventCategory: Purchase | Subscribe --> optional event on payment for better conversion
 * @param additionalOptions
 */
export const trackPage = (
    event: string,
    eventCategory?: string,
    additionalOptions?: Empty<Record<string, any>>,
): Promise<void> =>
    fetcher(API_ULRS.AppyCNT, {
        method: 'POST',
        body: JSON.stringify({
            userAgent: window.navigator.userAgent,
            event: event,
            eventCategory: eventCategory ? eventCategory : event,
            ...additionalOptions,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const cancelSubscriptions = async ({
    email,
    APP_URL,
    code,
}: {
    email: string;
    APP_URL: string;
    code: API_Prefix;
}): Promise<any> =>
    fetcher(`${APP_URL}/cancel-subscriptions`, {
        method: 'POST',
        body: JSON.stringify({
            email,
            code,
        }),
        headers: {
            'Content-Type': 'text/plain',
        },
    });

export const cancelSubscriptionById = async ({
    id,
    sign,
    code,
    APP_URL,
}: {
    id: string;
    sign: string;
    code: API_Prefix;
    APP_URL: string;
}): Promise<any> =>
    fetcher(`${APP_URL}/cancel-subscription`, {
        method: 'POST',
        body: JSON.stringify({
            id,
            sign: code + '/' + sign,
            code,
        }),
        headers: {
            'Content-Type': 'text/plain',
        },
    });

export async function getSubscriptions(code: API_Prefix, APP_URL: string): Promise<string | SubscriptionListType[]> {
    const pathAsArray = window.location.pathname.split('/').filter(Boolean);

    pathAsArray[0] = 'list-subscriptions/' + code;

    const finalPath = pathAsArray.join('/');

    return fetcher(`${APP_URL}/${finalPath}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
        },
    });
}

export function findInstagramUserByName(userName: any, signal: any) {
    return fetch('https://us-central1-appyfurious-9e7ca.cloudfunctions.net/getProfile', {
        method: 'POST',
        headers: {
            'x-api-token': 'LX3r5A389WTD9h7yenP6DnfhCv4LqcXD',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ target: userName }),
        signal,
    });
}

export const findSimilarUsersByName = (userName: any, signal: any) =>
    fetcher('https://us-central1-appyfurious-9e7ca.cloudfunctions.net/search', {
        method: 'POST',
        headers: {
            'x-api-token': 'LX3r5A389WTD9h7yenP6DnfhCv4LqcXD',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ target: userName }),
        signal,
    });
