import { createContext, useState } from 'react';

export const AuthorizationContext = createContext(null);

function AuthorizationProvider({ children }) {
    const [user, setUser] = useState(null);

    const value = { user, setUser };

    return <AuthorizationContext.Provider value={value}>{children}</AuthorizationContext.Provider>;
}

export default AuthorizationProvider;
