const isShowApplePay =
  new URLSearchParams(window.location.search).get('payby') === '9' ||
  new URLSearchParams(window.location.search).get('payby') === 'applepay';

//@ts-ignore
const isApplePayAvailable = window.ApplePaySession !== undefined;

const isOnRightDomain =
  window.location.origin.includes('profilereport.online') ||
  window.location.origin.includes('followersreport.com') ||
  window.location.origin.includes('socialstats.online') ||
  window.location.origin.includes('reportprofile.com') ||
  window.location.origin.includes('localhost');

export const isShouldRedirectForPayment = isShowApplePay && isApplePayAvailable && isOnRightDomain;

export const handleRedirectToPaymentPage = (withError?: string) => {
  const params = new URLSearchParams(window.location.search);

  if (withError) {
    params.delete('redirectDomain');
    params.delete('__page');
    params.delete('redirectCardTokenId');
    params.delete('redirectAppleTokenId');
  }

  const customParams = new URLSearchParams({
    __page: 'SUBSCRIPTION',
    redirectDomain: window.location.origin,
    ...(withError && { withError })
  });

  const redirectUrl = `https://app.billing-tracker.com${window.location.pathname}?${params}&${customParams}`;
  window.open(redirectUrl, '_self');
};

export const handleRedirectBackAfterPayment = (tokenId: string, email: string) => {
  const redirectDomain = new URLSearchParams(window.location.search).get('redirectDomain');

  let params = new URLSearchParams(window.location.search);
  params.delete('redirectDomain');
  params.delete('__page');
  params.delete('withError');

  const customParams = new URLSearchParams({
    redirectCardTokenId: tokenId,
    redirectUserEmail: email,
    __page: 'SIGNIN',
  });

  const redirectUrl = `${redirectDomain}${window.location.pathname}?${params}&${customParams}`;
  window.open(redirectUrl, '_self');
};
