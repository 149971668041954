import { useEffect } from 'react';

function useInitialReactPixel() {
    useEffect(() => {
        import('react-facebook-pixel')
            .then((response) => response.default)
            .then((ReactPixel) => {
                const fbPixelId = new URLSearchParams(document.location.search).get('fb');

                const finalPixelId = fbPixelId ? fbPixelId : process.env.REACT_APP_FB_PIXEL;

                ReactPixel.init(finalPixelId, undefined, {
                    autoConfig: true,
                    debug: false,
                });

                ReactPixel.pageView();
            });
    }, []);
}

export default useInitialReactPixel;
