import { useTranslation } from 'react-i18next';

import AppLogo from '../../../../components/AppLogo';

import * as S from './Manual.styles';
import Step from './components/Step';
import { getStepsData } from './Manual.constants';

function Manual() {
    const { t } = useTranslation();

    return (
        <S.Screen>
            <S.Container>
                <S.Inner>
                    <S.LogoWrapper className="mt-40">
                        <AppLogo />
                    </S.LogoWrapper>
                    <S.Title>{t('unSubscribe.manual.title')}</S.Title>
                    <S.Steps className="mt-40">
                        {getStepsData(t).map((step, index) => (
                            <Step key={index} {...step} index={index + 1} className="step" />
                        ))}
                    </S.Steps>
                </S.Inner>
            </S.Container>
        </S.Screen>
    );
}

export default Manual;
