import { useContext } from 'react';

import { AuthorizationContext } from './AuthorizationContext';

function useAuthorization() {
    const context = useContext(AuthorizationContext);

    if (!context) {
        throw new Error('use only context only provider');
    }

    return {
        ...context,
        isAuthorized: !!context.user,
    };
}

export default useAuthorization;
