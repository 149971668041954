import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as EmailIcon } from '../../../assets/svg/email.svg';

import * as S from './CheckEmailModal.styles';

function CheckEmailModal(props) {
    const {
        closeModal,
        innerProps: { onClick, text },
    } = props;
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            onClick && onClick();
        };
    }, [onClick]);

    function handleClick() {
        onClick && onClick();
        closeModal();
    }

    return (
        <S.Component>
            <S.Inner>
                <EmailIcon />
                <S.Title>{t('modals.checkEmailModal.title')}</S.Title>
                <S.SubTitle>{text ?? t('modals.checkEmailModal.text')}</S.SubTitle>
                <S.StyledButton id="CheckEmailModalBtn" onClick={handleClick}>
                    {t('modals.checkEmailModal.buttonTitle')}
                </S.StyledButton>
            </S.Inner>
        </S.Component>
    );
}

export default CheckEmailModal;
