import SunDeskImage1x from '../../assets/images/sun/sun-desktop-1x.png';
import SunDeskImage2x from '../../assets/images/sun/sun-desktop-2x.png';
import SunDeskImageW1x from '../../assets/images/sun/sun-desktop-1x.webp';
import SunDeskImageW2x from '../../assets/images/sun/sun-desktop-2x.webp';
import SunMobImage1x from '../../assets/images/sun/sun-mob-1x.png';
import SunMobImage2x from '../../assets/images/sun/sun-mob-2x.png';
import SunMobImageW1x from '../../assets/images/sun/sun-mob-1x.webp';
import SunMobImageW2x from '../../assets/images/sun/sun-mob-2x.webp';

import * as S from './HeaderImage.styles';

const HeaderImage = ({ isShort = false }) => (
    <S.HeaderImageWrapper>
        {isShort ? (
            <picture>
                <source
                    srcSet={`${SunMobImage1x} 1x, ${SunMobImage2x} 2x`}
                    media="(min-width: 1024px)"
                    type={'image/png'}
                />
                <source
                    srcSet={`${SunMobImageW1x} 1x, ${SunMobImageW2x} 2x`}
                    media="(min-width: 1024px)"
                    type={'image/webp'}
                />
                <img src={SunMobImage1x} alt={'sun-header'} style={{ marginBottom: '30px' }} />
            </picture>
        ) : (
            <picture>
                <source
                    srcSet={`${SunDeskImage1x} 1x, ${SunDeskImage2x} 2x`}
                    media="(min-width: 1024px)"
                    type={'image/png'}
                />
                <source
                    srcSet={`${SunDeskImageW1x} 1x, ${SunDeskImageW2x} 2x`}
                    media="(min-width: 1024px)"
                    type={'image/webp'}
                />
                <img src={SunDeskImage1x} alt={'sun-header'} />
            </picture>
        )}
    </S.HeaderImageWrapper>
);

export default HeaderImage;
