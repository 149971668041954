import { useState } from 'react';

import Form from './components/Form';
import Manual from './components/Manual';

function UnSubscribe() {
    const [showForm, setShowForm] = useState(true);

    return showForm ? <Form onNextStep={() => setShowForm(false)} /> : <Manual />;
}

export default UnSubscribe;
