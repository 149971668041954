import { useMemo } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import * as S from './App.styles';
import Quiz from './modules/Quiz';
import UnSubscribe from './modules/UnSubscribe';
import useRedirectToNativeBrowser from './hooks/utilHooks/useRedirectToNativeBrowser';
import useScrollPolyfill from './hooks/utilHooks/useScrollPolyfill';
import Subscriptions from './modules/Subscriptions';
import NotFound from './modules/NotFound';
import useInitialReactPixel from './hooks/loggerHooks/useInitialReactPixel';
//import useCheckAuth from "./hooks/useCheckAuth";
import usePreloadImages from './hooks/utilHooks/usePreloadImages';
import usePreloadCurrency from './hooks/utilHooks/usePreloadCurrency';
import { getPaymentParamsFromSessionStorage } from './services/payment';
import { PRELOADER_SIGN_IN_KEY } from './constants/quiz';
import useDocumentTitle from './hooks/utilHooks/useDocumentTitle';

function App() {
    useRedirectToNativeBrowser();
    useScrollPolyfill();
    //TODO: Uncomment when Apple auth returned
    //useCheckAuth();
    useInitialReactPixel();
    usePreloadImages();
    usePreloadCurrency();
    useDocumentTitle();

    const isShowSignInPopUp = useMemo(() => {
        const preloaderState = getPaymentParamsFromSessionStorage([PRELOADER_SIGN_IN_KEY]);
        return Object.keys(preloaderState).length !== 0;
    }, []);

    return (
        <S.AppWrapper>
            <BrowserRouter basename="/">
                <Routes>
                    <Route path="/" element={<Quiz isOpenSignInPopUp={isShowSignInPopUp} />} />
                    <Route path="/apiPath" element={<UnSubscribe />} />
                    <Route path="/activate/*" element={<Quiz isOpenSignInPopUp />} />
                    <Route path="/activate-me/*" element={<Quiz isOpenSignInPopUp />} />
                    <Route path="/apiPath-list/*" element={<Subscriptions />} />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </S.AppWrapper>
    );
}

export default App;

console.log(0.22);
