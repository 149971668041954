import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';

export const Component = styled.div`
    background: var(--bg-dark-color);
`;

export const Container = styled.div`
    max-width: 414px;
    width: 100%;
    margin: 0 auto;
`;

export const Content = styled.div`
    position: relative;
    width: 100%;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
`;

export const ContentWhite = styled(Content)`
    background-color: white;
`;

export const InnerWrapper = styled.div`
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;

    @media (max-width: 414px) {
        padding-top: 80px;
    }

    @media (max-width: 320px) {
        padding-top: 0;
    }
`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--bg-color);
`;

export const Image = styled.img``;

export const ImageWrapper = styled.div`
    position: relative;
    padding-top: 123.54%;

    ${Image} {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media (max-width: 320px) {
        padding-top: 0;
        ${Image} {
            position: relative;
        }
    }
`;

export const Information = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--textPrimary-color);
    padding: 0 20px;
`;

export const Title = styled.span`
    margin-top: 30px;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: var(--textPrimary-color);
`;

export const SubTitle = styled.span`
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--textPrimary-color);
`;

export const Pagination = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PaginationInner = styled.div`
    display: flex;
`;

export const PaginationItem = styled.span`
    flex-shrink: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--bg-dark-color);
    margin-right: 16px;

    &.active {
        background: var(--textPrimary-color);
    }

    &:last-child {
        margin-right: 0;
    }
`;

export const NextButton = styled(Button)`
    position: sticky;
    bottom: 20px;
    margin: 30px 0 20px;
    width: 100%;
`;

export const Separator = styled.div`
    margin: 40px 0 40px;
    width: 100%;
    height: 1px;
    background: var(--gray50-color);
`;

export const ProceedSeparator = styled(Separator)`
    margin: 26px 0;
    position: relative;
    height: auto;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    background: none;
    color: var(--textPrimary-color);

    &.short-mode {
        margin: 10px 0;
    }

    &:after,
    &:before {
        position: absolute;
        content: '';
        width: 30%;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        background: rgba(189, 185, 182, 0.3);

        @media (max-width: 375px) {
            width: 25%;
        }
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }
`;

export const LogoWrapper = styled.div`
    margin: 0 auto;

    svg {
        width: 64px;
        height: 64px;
    }
`;

/** footer**/

export const Bottom = styled.div`
    margin-top: auto;
    width: 100%;
    padding: 40px 0;
    border-top: 1px solid rgba(189, 185, 182, 0.3);
    text-align: center;
    overflow: hidden;
`;

export const BottomText = styled.span`
    font-size: 14px;
    line-height: 19px;
    color: var(--textSecondary-color);

    a {
        color: var(--primary-color);
        text-decoration: underline;
        text-decoration-color: var(--primary-color);
    }
`;

export const BottomLink = styled(Link)`
    color: var(--primary-color);
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
`;
