function execCommand(..._args) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
}

export const gtag = {
    /**
     * Reference:
     * {@link https://developers.google.com/gtagjs/reference/api#config}
     */
    config(targetId, configInfo = {}) {
        execCommand('config', targetId, configInfo);
    },
    /**
     * Reference:
     * {@link https://developers.google.com/gtagjs/reference/api#get}
     */
    get(target, fieldName, callback) {
        execCommand('get', target, fieldName, callback);
    },
    /**
     * Reference:
     * {@link https://developers.google.com/gtagjs/reference/api#set}
     */
    set(params) {
        execCommand('set', params);
    },
    /**
     * Reference:
     * {@link https://developers.google.com/gtagjs/reference/api#event}
     */
    event(eventName, eventParams = {}) {
        execCommand('event', eventName, eventParams);
    },
    js(startDate) {
        execCommand('js', startDate);
    },
};

function loadGtagScript(measurementId) {
    /**
     * Reference:
     * {@link https://developers.google.com/analytics/devguides/collection/gtagjs#install_the_global_site_tag}
     */
    const scriptSrc = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    const script = document.createElement('script');
    script.async = true;
    script.src = scriptSrc;
    document.body.appendChild(script);
}

class GtagManager {
    constructor() {
        this.measurementId = '';
        this.isInitialized = false;
    }

    init(measurementId) {
        if (this.isInitialized) return;

        this.measurementId = measurementId;

        loadGtagScript(this.measurementId);

        this.isInitialized = true;

        /**
         * I don't know why we need this command, but the example contains it
         * {@link https://developers.google.com/analytics/devguides/collection/gtagjs#install_the_global_site_tag}
         */
        gtag.js(new Date());

        gtag.config(this.measurementId);
    }
}

export const gtagManager = new GtagManager();
