import { ReactElement, useMemo } from 'react';

import { Helmet } from 'react-helmet';

const InitScripts = (): ReactElement => {
    const appName: string = useMemo(
        () => (window.location.host.includes('followersreport') ? 'SocialSpy: Profile Analyzer' : 'Profile Report+'),
        [window.location.host],
    );

    return (
        <Helmet>
            <meta name="application-name" content={appName} />
            <meta property="og:title" content={appName} />
            <meta property="og:site_name" content={appName} />
            <title>{appName}</title>
        </Helmet>
    );
};

export default InitScripts;
