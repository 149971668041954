import styled from 'styled-components';

export const Screen = styled.div`
    width: 100%;
    min-height: 100%;
    height: auto;
    background: var(--bg-color);

    &.no-subscriptions {
        display: flex;
        align-items: center;
    }
`;

export const Spinner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const LogoWrapper = styled.div`
    padding-top: 20px;
    display: flex;
    justify-content: center;
`;

export const Title = styled.h1`
    margin: 30px 0 0 0;
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
    color: var(--textPrimary-color);
    text-align: center;
`;

export const Subscriptions = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .subscription-card {
        margin-bottom: 16px;
    }
`;

export const BottomText = styled.div`
    position: relative;
    margin: auto 0 0 0;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--textSecondary-color);
    padding-top: 24px;
    padding-bottom: 40px;

    a {
        color: var(--primary-color);
        text-decoration: underline;
        text-decoration-color: var(--primary-color);
    }

    &.no-subscriptions {
        margin-top: 40px;
        border-top: 1px solid var(--gray100-color);
        padding-top: 40px;
    }
`;

export const SubTitle = styled.span`
    margin-top: 15px;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--textPrimary-color);
`;

export const Component = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;

    &.no-subscriptions {
        height: auto;
        justify-content: center;

        @media (max-width: 768px) {
            justify-content: flex-start;
        }

        ${LogoWrapper} {
            padding-top: 0;

            @media (max-height: 400px) {
                padding-top: 20px;
            }
        }
    }
`;

export const HeaderImageWrapper = styled.div`
    margin: 0 auto;
`;
