import styled from 'styled-components';

import S from '../../UnSubscribe.styles';
import Button from '../../../../components/Button';

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px 20px 0;
    color: white;
    min-height: 100vh;
    height: auto;
    overflow: hidden;
`;

export const ErrorMessage = styled.span``;

export const StyledButton = styled(Button)`
    width: 100%;
    padding-top: 17px;
    padding-bottom: 17px;
    margin-top: 30px;
    font-size: 16px;
    line-height: 22px;

    &:disabled {
        color: white;
        background: #dfdbd7;
    }
`;

export default S;
