import { useTranslation } from 'react-i18next';

//import usePaymentConfig from '../../../../../../providers/PaymentConfigContext/PaymentConfigContext.hooks';
import { isIos } from '../../../../../../utils/common';
import useAppContext from '../../../../../../providers/AppContext/AppContext.hooks';
import { DownloadLinks } from '../../../../../../constants/url';
import AppLogo from '../../../../../../components/AppLogo';

import * as S from './DownloadApp.styles';

function DownloadApp() {
    //const { paymentConfig } = usePaymentConfig();
    const isIosDevice = isIos();
    const { t } = useTranslation();
    // @ts-ignore
    const { appDomain } = useAppContext();

    // const paymentDownloadLink = isIosDevice
    //   ? paymentConfig?.download?.ios
    //   : paymentConfig?.download?.android;

    function onClick() {
        // @ts-ignore
        window.open(DownloadLinks[appDomain.appName]);
    }

    const appName = appDomain.isFollowersreport ? 'SocialSpy: Profile Analyzer' : 'Profile Report+';

    return (
        <S.Component>
            <S.LogoWrapper>
                <AppLogo />
            </S.LogoWrapper>
            <S.Group>
                <S.Title>{appName}</S.Title>
                <S.SubTitle>{t('manual.downloadApp.subTitle')}</S.SubTitle>
            </S.Group>
            <S.Button onClick={onClick}>
                {t('unSubscribe.manual.download').replace('{{}}', isIosDevice ? 'iOS' : 'Android')}
            </S.Button>
        </S.Component>
    );
}

export default DownloadApp;
