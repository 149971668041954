import { createContext, useState } from 'react';

export const CurrencyContext = createContext(null);

function CurrencyProvider({ children }) {
    const [currency, setCurrency] = useState('USD');
    const [currencyRate, setCurrencyRate] = useState({
        currencyCode: 'USD',
        rate: 1,
    });

    const value = { currency, setCurrency, currencyRate, setCurrencyRate };

    return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>;
}

export default CurrencyProvider;
