import styled, { css } from 'styled-components';

export const Button = styled.button`
    display: inline-block;
    position: relative;
    border: none;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: white;
    padding: 17px 43px;
    ${(props) => props.variantStyles};
    border-radius: 16px;

    ${(props) =>
        props.withIcon &&
        css`
            padding-right: 91px;
        `};

    @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
    }
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
