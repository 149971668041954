export const EV = {
    LOADED: 'LOADED',
    SUB_SCREEN_SHOWN: 'SUB_SCREEN_SHOWN',
    STEP: 'STEP',
    INITIATED_CHECKOUT: 'INITIATED_CHECKOUT',
    SUB_SCREEN_CLICK: 'SUB_SCREEN_CLICK',
    SUB_EMAIL_CLOSE: 'SUB_EMAIL_CLOSE',
    SUB_PAYMENT_CLOSE: 'SUB_PAYMENT_CLOSE',
    SUB_EMAIL_WITH_APPLE: 'SUB_EMAIL_WITH_APPLE',
    PURCHASE: 'PURCHASE',
    SUBSCRIBE: 'SUBSCRIBE',
    CONTACT: 'CONTACT',
    DOWNLOAD: 'DOWNLOAD',
};

export const appyCntEvents = (param = '', appName) => {
    return {
        [EV.LOADED]: `${appName} Landing Loaded`,
        [EV.STEP]: `${appName} Landing Step ${String(param)}`,
        [EV.SUB_SCREEN_SHOWN]: `${appName} Sub Screen Shown`,
        [EV.INITIATED_CHECKOUT]: `${appName} InitiatedCheckout`,
        [EV.SUB_SCREEN_CLICK]: `${appName} Sub Screen Click`,
        [EV.SUB_EMAIL_WITH_APPLE]: `${appName} Sub Email with AppPay`,
        [EV.SUB_EMAIL_CLOSE]: `${appName} Sub Email Close`,
        [EV.SUB_PAYMENT_CLOSE]: `${appName} Sub Payment Close`,
        [EV.PURCHASE]: `${appName} Sub Purchase`,
        [EV.SUBSCRIBE]: `${appName} Sub Subscribe`,
        [EV.CONTACT]: `${appName} Contact Click`,
        [EV.DOWNLOAD]: `${appName} Manual Screen`,
    };
};
