export const pixelTrackCustom = (event, data) => {
    try {
        import('react-facebook-pixel')
            .then((response) => response.default)
            .then((ReactPixel) => {
                ReactPixel.trackCustom(event, data);
            });
    } catch (e) {
        console.warn('Could not track custom pixel event: ', event);
    }
};

export const pixelTrackStandard = (event, data) => {
    try {
        import('react-facebook-pixel')
            .then((response) => response.default)
            .then((ReactPixel) => {
                ReactPixel.track(event, data);
            });
    } catch (e) {
        console.warn('Could not track standard pixel event: ', event);
    }
};
