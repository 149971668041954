import { BasePaths, Emails, AppName, DomainURLS, API_Prefix } from '../../constants/url';

import { AppURLSType } from './AppContext.typings';

export const appUrls = (): AppURLSType => {
    const emptyApp = {
        isInsighter: false,
        isFollowerse: false,
        isProfilereport: false,
        isFollowersreport: false,
        isSocialstats: false,
        isBillingtracker: false,
        isReportprofile: false,
        isLocalhost: false,
    }

    switch (true) {
        case window.location.origin.includes('reportprofile.com'):
            return {
                domain: DomainURLS.REPORTPROFILE,
                appName: AppName.REPORTPROFILE,
                ...emptyApp,
                isReportprofile: true,
                appUrl: {
                    url: window.location.origin,
                    basePath: BasePaths.Reportprofile,
                    apiPath: API_Prefix.REPORTPROFILE,
                },
                email: {
                    label: Emails.Reportprofile,
                    url: `mailto:${Emails.Reportprofile}`,
                },
            };
        case window.location.origin.includes('socialstats.online'):
            return {
                domain: DomainURLS.SOCIALSTATS,
                appName: AppName.SOCIALSTATS,
                ...emptyApp,
                isSocialstats: true,
                appUrl: {
                    url: window.location.origin,
                    basePath: BasePaths.Socialstats,
                    apiPath: API_Prefix.SOCIALSTATS,
                },
                email: {
                    label: Emails.Socialstats,
                    url: `mailto:${Emails.Socialstats}`,
                },
            };
        case window.location.origin.includes('followersreport.com'):
            return {
                domain: DomainURLS.FOLLOWERSREPORT,
                appName: AppName.FOLLOWERSREPORT,
                ...emptyApp,
                isFollowersreport: true,
                appUrl: {
                    url: window.location.origin,
                    basePath: BasePaths.Followersreport,
                    apiPath: API_Prefix.FOLLOWERSREPORT,
                },
                email: {
                    label: Emails.Followersreport,
                    url: `mailto:${Emails.Followersreport}`,
                },
            };
        case window.location.origin.includes('profilereport.online'):
            return {
                domain: DomainURLS.PROFILEREPORT,
                appName: AppName.PROFILEREPORT,
                ...emptyApp,
                isProfilereport: true,
                appUrl: {
                    url: window.location.origin,
                    basePath: BasePaths.Profilereport,
                    apiPath: API_Prefix.PROFILEREPORT,
                },
                email: {
                    label: Emails.Profilereport,
                    url: `mailto:${Emails.Profilereport}`,
                },
            };
        case window.location.origin.includes('insighter.live'):
            return {
                domain: DomainURLS.INSIGHTER,
                appName: AppName.INSIGHTER,
                ...emptyApp,
                isInsighter: true,
                appUrl: {
                    url: window.location.origin,
                    basePath: BasePaths.Insighter,
                    apiPath: API_Prefix.INSIGHTER,
                },
                email: {
                    label: Emails.Insighter,
                    url: `mailto:${Emails.Insighter}`,
                },
            };
        case window.location.origin.includes('followerse.online'):
            return {
                domain: DomainURLS.FOLLOWERSE,
                appName: AppName.FOLLOWERSE,
                ...emptyApp,
                isFollowerse: true,
                appUrl: {
                    url: window.location.origin,
                    basePath: BasePaths.Followerse,
                    apiPath: API_Prefix.FOLLOWERSE,
                },
                email: {
                    label: Emails.Followerse,
                    url: `mailto:${Emails.Followerse}`,
                },
            };
        case window.location.origin.includes('billing-tracker.com'): {
            const redirectDomain = new URLSearchParams(window.location.search).get('redirectDomain');

            if (redirectDomain !== null && redirectDomain.includes('profilereport.online')) {
                return {
                    domain: DomainURLS.PROFILEREPORT,
                    appName: AppName.PROFILEREPORT,
                    ...emptyApp,
                    isProfilereport: true,
                    appUrl: {
                        url: window.location.origin,
                        basePath: BasePaths.Profilereport,
                        apiPath: API_Prefix.PROFILEREPORT,
                    },
                    email: {
                        label: Emails.Profilereport,
                        url: `mailto:${Emails.Profilereport}`,
                    },
                };
            }

            if (redirectDomain !== null && redirectDomain.includes('followersreport.com')) {
                return {
                    domain: DomainURLS.FOLLOWERSREPORT,
                    appName: AppName.FOLLOWERSREPORT,
                    ...emptyApp,
                    isFollowersreport: true,
                    appUrl: {
                        url: window.location.origin,
                        basePath: BasePaths.Followersreport,
                        apiPath: API_Prefix.FOLLOWERSREPORT,
                    },
                    email: {
                        label: Emails.Followersreport,
                        url: `mailto:${Emails.Followersreport}`,
                    },
                };
            }

            if (redirectDomain !== null && redirectDomain.includes('reportprofile.com')) {
                return {
                    domain: DomainURLS.REPORTPROFILE,
                    appName: AppName.REPORTPROFILE,
                    ...emptyApp,
                    isReportprofile: true,
                    appUrl: {
                        url: window.location.origin,
                        basePath: BasePaths.Reportprofile,
                        apiPath: API_Prefix.REPORTPROFILE,
                    },
                    email: {
                        label: Emails.Reportprofile,
                        url: `mailto:${Emails.Reportprofile}`,
                    },
                };
            }

            return {
                domain: DomainURLS.BILLINGTRACKER,
                appName: AppName.BILLINGTRACKER,
                ...emptyApp,
                isBillingtracker: true,
                appUrl: {
                    url: window.location.origin,
                    basePath: BasePaths.Billingtracker,
                    apiPath: API_Prefix.BILLINGTRACKER,
                },
                email: {
                    label: Emails.Billingtracker,
                    url: `mailto:${Emails.Billingtracker}`,
                },
            };
        }
        default:
            return {
                domain: DomainURLS.LOCALHOST,
                appName: AppName.INSIGHTER,
                ...emptyApp,
                isLocalhost: true,
                appUrl: {
                    url: 'https://dev.insighter.live',
                    basePath: BasePaths.Insighter,
                    apiPath: API_Prefix.INSIGHTER,
                },
                email: {
                    label: Emails.Insighter,
                    url: `mailto:${Emails.Insighter}`,
                },
            };
    }
};
