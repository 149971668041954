/**
 * FB Pixel standard events
 * @see https://facebook.com/business/help/402791146561655?id=1205376682832142
 */
export const PixelEvents = {
    ViewContent: 'ViewContent',
    Contact: 'Contact',
    CompleteRegistration: 'CompleteRegistration',
    InitiateCheckout: 'InitiateCheckout',
    Purchase: 'Purchase',
    Subscribe: 'Subscribe',
};
