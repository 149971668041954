import { createContext, useState } from 'react';

export const PaymentEmailContext = createContext(null);

function PaymentEmailProvider({ children }) {
    const [paymentEmail, setPaymentEmail] = useState('');
    const [paymentAppleEmail, setPaymentAppleEmail] = useState('');
    const [subscriptionId, setSubscriptionId] = useState('');

    const value = {
        subscriptionId,
        paymentEmail,
        setPaymentEmail,
        paymentAppleEmail,
        setPaymentAppleEmail,
        setSubscriptionId,
    };

    return <PaymentEmailContext.Provider value={value}>{children}</PaymentEmailContext.Provider>;
}

export default PaymentEmailProvider;
