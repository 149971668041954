import { appyCntEvents } from '../../services/analytics/analyticEvents/appyCntEvents';
import { pixelTrackCustom } from '../../services/analytics/fPixel';
import { gtag } from '../../services/analytics/gtag';
import useAppContext from '../../providers/AppContext/AppContext.hooks';

import useLogger from './useLogger';

/**
 * Track single events
 * @returns {(function(*, *=, *=): void)|*}
 */
export const useTrackEvent = () => {
    const { trackAppyCntEvent } = useLogger();
    const { appDomain } = useAppContext();
    return (event, eventPrefix = undefined, eventType = undefined, options = {}) => {
        trackAppyCntEvent(appyCntEvents(eventPrefix, appDomain.appName)[event], eventType, options);
        pixelTrackCustom(appyCntEvents(eventPrefix, appDomain.appName)[event]);
        gtag.event(appyCntEvents(eventPrefix, appDomain.appName)[event]);
    };
};
