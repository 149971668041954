import { createContext, useEffect, useState } from 'react';

import cookie from 'js-cookie';

import { getLocationData } from '../services/requests';
import { getFacebookClickParameter } from '../utils/loggerUtils';

export const AttributionContext = createContext(null);

const searchParams = new URLSearchParams(document.location.search);

const getParamByKey = (key) => searchParams.get(key) ?? cookie.get(key) ?? '';

function AttributionProvider({ children }) {
    const { _fbp, _fbc } = getFacebookClickParameter();
    const [isAttributesLoaded, setIsAttributesLoaded] = useState(false);

    const [state, setState] = useState({
        campaign_id: getParamByKey('campaign_id'),
        campaign_name: getParamByKey('campaign_name'),
        adset_id: getParamByKey('adset_id'),
        adset_name: getParamByKey('adset_name'),
        ad_id: getParamByKey('ad_id'),
        media_source: getParamByKey('media_source'),
        config: getParamByKey('config'),
        country_code: '',
        ad_name: getParamByKey('ad_name'),
        fbc: cookie.get('_fbc') ?? _fbc,
        fbp: getParamByKey('_fbp') ?? _fbp,
        event_source_url: window.location.href,
        client_user_agent: navigator.userAgent,
        client_ip_address: '',
    });

    useEffect(() => {
        getLocationData()
            .then((response) => {
                setState({
                    ...state,
                    country_code: response.country ?? '',
                    client_ip_address: response.ip ?? '',
                });
            })
            .catch(console.error)
            .finally(() => {
                setIsAttributesLoaded(true);
            });
    }, []);

    const value = {
        isAttributesLoaded,
        attribution: state,
    };

    return <AttributionContext.Provider value={value}>{children}</AttributionContext.Provider>;
}

export default AttributionProvider;
