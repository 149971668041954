import { useState } from 'react';

import { convertStringToBoolean } from '../../utils/common';
import useAppContext from '../../providers/AppContext/AppContext.hooks';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

/**
 * Show|hide landing by condition
 * -----------------
 * ?quiz=yes - show landing
 * ?quiz=no | undefined - redirect to web2app
 */
export const useRedirectToWeb2App = ({ showLanding }: { showLanding: boolean }): { isShowLanding: boolean } => {
    const [isShowLanding, setIsShowLanding] = useState(showLanding);
    const appContext = useAppContext();
    useIsomorphicLayoutEffect(() => {
        if (!showLanding) {
            if (!convertStringToBoolean(new URLSearchParams(window.location.search).get('quiz') ?? 'no')) {
                if (appContext?.appDomain.isBillingtracker) {
                    window.location.replace('/ecoturismo/' + window.location.search);
                } else if (appContext?.appDomain.isFollowersreport) {
                    window.location.replace('/followersreport/' + window.location.search);
                } else if (appContext?.appDomain.isProfilereport) {
                    window.location.replace('/profilereport/' + window.location.search);
                } else if (appContext?.appDomain.isSocialstats) {
                    window.location.replace('/socialstats/' + window.location.search);
                } else if (appContext?.appDomain.isReportprofile) {
                    window.location.replace('/reportprofile/' + window.location.search);
                } else if (!window.location.href.includes('web2app')) {
                    window.location.replace('/web2app/' + window.location.search);
                }
            } else {
                setIsShowLanding(true);
            }
        }
    }, []);

    return { isShowLanding };
};
