import { createContext, useEffect, useState } from 'react';

import { Elements, RecurlyProvider } from '@recurly/react-recurly';

import { getPaymentConfig } from '../../services/requests';
import useAppContext from '../AppContext/AppContext.hooks';

import { initialSalePlans } from './PaymentConfigContext.helpers';

export const PaymentConfigContext = createContext(null);

function PaymentConfigProvider({ children }) {
    const [paymentConfig, setPaymentConfig] = useState(null);
    const {
        appDomain: { appUrl },
    } = useAppContext();

    useEffect(() => {
        getPaymentConfig({ appUrl, searchString: window.location.search })
            .then((response) => {
                if (response) {
                    const convertedPlans = (response.plans ?? []).map((plan) => ({
                        ...plan,
                        fakeDiscountPercent: 90,
                    }));

                    const originPlans = convertedPlans.filter((plan) => !plan.id.endsWith('_sale'));

                    const salePlans = convertedPlans.filter((plan) => plan.id.endsWith('_sale'));

                    const initialPlan = originPlans[0];

                    setPaymentConfig({
                        ...response,
                        plans: convertedPlans,
                        salePlans: initialSalePlans(salePlans, originPlans),
                        notSalePlans: originPlans,
                        viewPlans: convertedPlans.filter((plan) => !plan.id.endsWith('_sale')),
                        selectedPlan: initialPlan,
                        isSinglePaymentMode: convertedPlans.length === 1,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    function updateSelectedPlan(plan) {
        setPaymentConfig({ ...paymentConfig, selectedPlan: plan });
    }

    function updateViewPlans(plan) {
        setPaymentConfig({
            ...paymentConfig,
            selectedPlan: plan,
            viewPlans: paymentConfig.viewPlans.map((viewPlan) => {
                if (viewPlan.id + '_sale' === plan.id) {
                    return plan;
                } else {
                    return viewPlan;
                }
            }),
        });
    }

    const value = {
        paymentConfig: paymentConfig,
        updateSelectedPlan,
        updateViewPlans,
    };

    const publicKey = paymentConfig?.recurly?.pk ?? process.env.REACT_APP_RECURLY_KEY;

    return (
        <PaymentConfigContext.Provider value={value}>
            <RecurlyProvider publicKey={publicKey} key={publicKey}>
                <Elements>{children}</Elements>
            </RecurlyProvider>
        </PaymentConfigContext.Provider>
    );
}

export default PaymentConfigProvider;
