export enum DomainURLS {
    BILLINGTRACKER = 'track-followers/ecoturismo',
    INSIGHTER = 'track-followers/insighter',
    FOLLOWERSE = 'track-followers/followerse',
    PROFILEREPORT = 'track-followers/profilereport',
    FOLLOWERSREPORT = 'track-followers/followersreport',
    SOCIALSTATS = 'track-followers/socialstats',
    REPORTPROFILE = 'track-followers/reportprofile',
    PUBLICSTATS = 'track-followers/publicstats',
    FOLLOWERREPORTS = 'track-followers/followerreports',
    LOCALHOST = 'track-followers/web2app',
}

export enum AppName {
    BILLINGTRACKER = 'Billng Tracker',
    INSIGHTER = 'SocialMetric',
    FOLLOWERSE = 'Followerse',
    PROFILEREPORT = 'SocialSpy',
    FOLLOWERSREPORT = 'SocialSpy: Profile Analyzer',
    SOCIALSTATS = 'SocialStats: Profile Analytics',
    REPORTPROFILE = 'Report Profile',
    PUBLICSTATS = 'Public Stats',
    FOLLOWERREPORTS = 'Follower Reports',
}

export enum API_Prefix {
    BILLINGTRACKER = 'ecoturismo',
    INSIGHTER = 'insighter',
    FOLLOWERSE = 'followers',
    PROFILEREPORT = 'profilereport',
    SOCIALSTATS = 'socialstats',
    FOLLOWERSREPORT = 'followersreport',
    REPORTPROFILE = 'reportprofile',
    PUBLICSTATS = 'publicstats',
    FOLLOWERREPORTS = 'followerreports',
}

export enum API_ULRS {
    'AppyCNT' = 'https://events.appycnt.com/event',
    'LocationIP' = 'https://resolve.appycnt.com/remote-addr',
}

export enum BasePaths {
    Billingtracker = `https://billing-tracker.com/track-followers/${API_Prefix.BILLINGTRACKER}`,
    Insighter = `https://insighter.live/track-followers/${API_Prefix.INSIGHTER}`,
    Followerse = `https://followerse.online/track-followers/${API_Prefix.FOLLOWERSE}`,
    Profilereport = `https://profilereport.online/track-followers/${API_Prefix.PROFILEREPORT}`,
    Socialstats = `https://socialstats.online/track-followers/${API_Prefix.SOCIALSTATS}`,
    Followersreport = `https://followersreport.com/track-followers/${API_Prefix.FOLLOWERSREPORT}`,
    Reportprofile = `https://reportprofile.com/track-followers/${API_Prefix.REPORTPROFILE}`,
    Publicstats = `https://public-stats.com/track-followers/${API_Prefix.PUBLICSTATS}`,
    Followerreports = `https://followerreports.com/track-followers/${API_Prefix.FOLLOWERREPORTS}`,
}

export enum Emails {
    Billingtracker = 'support@followersreport.com',
    Insighter = 'support@followersreport.com',
    Followerse = 'support@followersreport.com',
    Profilereport = 'support@followersreport.com',
    Socialstats = 'support@followersreport.com',
    Followersreport = 'support@followersreport.com',
    Reportprofile = 'support@followersreport.com',
    Publicstats = 'support@followersreport.com',
    Followerreports = 'support@followersreport.com',
}

export const DownloadLinks: Record<AppName, string> = {
    [AppName.BILLINGTRACKER]: 'https://metricgurus.com/',
    [AppName.INSIGHTER]: 'https://metricgurus.com/',
    [AppName.FOLLOWERSE]: 'https://metricgurus.com/',
    [AppName.PROFILEREPORT]: 'https://metricgurus.com/',
    [AppName.SOCIALSTATS]: 'https://metricgurus.com/',
    [AppName.FOLLOWERSREPORT]: 'https://metricgurus.com/',
    [AppName.REPORTPROFILE]: 'https://metricgurus.com/',
    [AppName.PUBLICSTATS]: 'https://metricgurus.com/',
    [AppName.FOLLOWERREPORTS]: 'https://metricgurus.com/',
} as const;
