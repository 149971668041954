import { useTranslation } from 'react-i18next';

import { Component, Title } from './NotFound.styles';

function NotFound() {
    const { t } = useTranslation();
    return (
        <Component>
            <Title>{t('notFound.title')}</Title>
        </Component>
    );
}

export default NotFound;
