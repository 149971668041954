import { useEffect } from 'react';

import { convertStringToBoolean, getOS, isIOS14Check, isMetaInAppBrowser } from '../../utils/common';

function useRedirectToNativeBrowser() {
    useEffect(() => {
        const isStartAppRoute = window.location.pathname === '/';

        if (isMetaInAppBrowser && isStartAppRoute) {
            const bb = new URLSearchParams(document.location.search).get('bb');

            const shouldRedirect = convertStringToBoolean(bb ? bb : 'no');

            if (shouldRedirect) {
                switch (getOS()) {
                    case 'ios':
                        if (isIOS14Check) {
                            window.location.href =
                                'ftp://files.devroqapps.com/redirect.html?url=' +
                                encodeURIComponent(window.location.href);
                        } else {
                            window.location.href =
                                'googlechrome-x-callback://x-callback-url/open/?url=' +
                                encodeURIComponent(window.location.href);
                        }
                        break;
                    case 'android':
                        window.location.href =
                            'https://redirect.streamspace.club/redirect.php?url=' +
                            encodeURIComponent(window.location.href);
                        break;
                    default:
                        break;
                }
            }
        }
    }, []);
}

export default useRedirectToNativeBrowser;
