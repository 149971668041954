import { useEffect } from 'react';

import useCurrency from '../../providers/CurrencyContext/CurrencyContext.hooks';
import { fetcher } from '../../utils/fetcher';

const countryCurrencyMapping: Record<string, string> = {
    pt: 'EUR',
    es: 'EUR',
    it: 'EUR',
    de: 'EUR',
    fr: 'EUR',
    gr: 'EUR',
    cz: 'CZK',
    id: 'IDR',
    ja: 'JPY',
    vi: 'VND',
    tr: 'TRY',
    ru: 'RUB',
    ua: 'UAH',
    mx: 'MXN',
    cl: 'CLP',
    br: 'BRL',
    en: 'USD',
    us: 'USD',
};

const getRates = async () => fetcher('https://rates.devroqapps.com/');

function usePreloadCurrency(): void {
    const { setCurrency, setCurrencyRate } = useCurrency();
    const langFromConfig = String(new URLSearchParams(window.location.search).get('locale') ?? navigator.language);

    const currencyCodeMappedValue: string =
        countryCurrencyMapping[langFromConfig.length > 4 ? langFromConfig?.slice(3).toLowerCase() : 'en'];
    setCurrency(currencyCodeMappedValue);

    useEffect(() => {
        getRates()
            .then((result: any) => {
                if (result) {
                    setCurrencyRate({
                        currencyCode: currencyCodeMappedValue ?? 'USD',
                        rate: result[currencyCodeMappedValue] ?? 1,
                    });
                }
            })
            .catch((e) => console.error('Error in getRates request ', e));
    }, []);
}

export default usePreloadCurrency;
