import { createContext, useRef, useState } from 'react';

import { QUIZ_STEPS_MAP } from '../../constants/quiz';
import { JSXChild } from '../../model/typings/common';

import { appUrls } from './AppContext.functions';
import { AppContextType, AppURLSType } from './AppContext.typings';

export const AppContext = createContext<AppContextType>(null);

const AppProvider = ({ children }: JSXChild) => {
    const [step, setStep] = useState(QUIZ_STEPS_MAP.LANDING);
    const [isOpenSignInPopUp, setIsOpenSignInPopUp] = useState(false);
    const appUrlsValue = appUrls();
    const appDomain = useRef<AppURLSType>(appUrlsValue);
    console.log(appUrlsValue)

    const value = { step, isOpenSignInPopUp, setStep, setIsOpenSignInPopUp, appDomain: appDomain.current };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
