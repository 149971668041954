import styled from 'styled-components';

export const Screen = styled.div`
    width: 100%;
    background: var(--bg-color);
    min-height: 100vh;
    height: auto;
`;

export const Container = styled.div`
    max-width: 548px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    color: black;
`;

export const LogoWrapper = styled.div`
    width: 64px;
    height: 64px;

    img {
        width: 64px;
        height: 64px;
    }
`;

export const ImageWrapper = styled.div`
    width: 100%;
    position: relative;
    box-shadow: 0 8px 16px rgba(216, 216, 216, 0.3);
    border-radius: 16px;
    background: white;
    height: 270px;

    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }
`;

export const Title = styled.h1`
    margin: 40px 0 0 0;
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
`;

export const Steps = styled.div`
    border: 1px solid #e8e8e8;
    padding: 20px;
    border-radius: 16px;
    margin-bottom: 24px;

    .step {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    ${ImageWrapper} {
        img {
            left: 50%;
            transform: translateX(-50%);
        }

        .phone-image-1 {
            width: 320px;
            height: 230px;
        }

        .phone-image-2 {
            width: 400px;
            height: 230px;
            margin-top: auto !important;
            bottom: 0;
        }

        .phone-image-3 {
            width: 320px;
            height: 230px;
        }

        .phone-image-4 {
            width: 320px;
            height: 230px;
        }

        @media (max-width: 416px) {
            height: 190px;

            .phone-image-1 {
                width: 220px;
                height: 157px;
            }

            .phone-image-2 {
                width: 276px;
                height: 159px;
            }

            .phone-image-3 {
                width: 220px;
                height: 157px;
            }

            .phone-image-4 {
                width: 220px;
                height: 157px;
            }
        }

        @media (max-width: 320px) {
            height: 190px;

            .phone-image-1 {
                width: 180px;
                height: 128px;
            }

            .phone-image-2 {
                width: 224px;
                height: 129px;
            }

            .phone-image-3 {
                width: 180px;
                height: 128px;
            }

            .phone-image-4 {
                width: 180px;
                height: 128px;
            }
        }
    }
`;
