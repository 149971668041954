import { useContext } from 'react';

import { AmplitudeLogging } from '../../providers/LoggerProvider/LoggerProvider';

function useLogger() {
    const context = useContext(AmplitudeLogging);

    if (!context) {
        throw new Error('use only inside the provider');
    }

    return {
        trackAmplitudeEvent: context.trackAmplitudeEvent,
        trackAppyCntEvent: context.trackAppyCntEvent,
    };
}

export default useLogger;
