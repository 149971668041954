import styled from 'styled-components';

export const Component = styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: 40px;
`;

export const Index = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border: 1px solid #dddddd;
    margin-right: 16px;
    border-radius: 50%;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;

export const Content = styled.div`
    width: 100%;
`;

export const Title = styled.span`
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #000000;

    @media (max-width: 440px) {
        font-size: 16px;
        line-height: 24px;
    }
`;
