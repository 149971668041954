import styled from 'styled-components';

import * as S from '../Quiz/Quiz.styles';

export const Component = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const Title = styled.h1`
    width: 100%;
    padding: 0 20px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 56px;
    line-height: 76px;
    color: var(--textPrimary-color);
    z-index: 2;
    text-align: center;

    @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 38px;
        text-align: center;
    }
`;

export default S;
