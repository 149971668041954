import styled from 'styled-components';

export const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    svg {
        width: 60px !important;
        height: 60px !important;
    }
`;

export const Group = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;

export const Title = styled.span`
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
`;

export const SubTitle = styled.span`
    margin-top: 2px;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    opacity: 0.5;
`;

export const Button = styled.button`
    font-weight: 600;
    margin-left: auto;
    width: 78px;
    color: var(--primary-color);
    text-align: right;
    height: 39px;
`;

export const Component = styled.div`
    display: flex;
    align-items: center;

    background: #ffffff;
    box-shadow: 0 8px 16px rgba(216, 216, 216, 0.3);
    border-radius: 16px;
    padding: 24px;

    @media (max-width: 375px) {
        flex-direction: column;

        ${LogoWrapper} {
            margin-right: 0;
        }

        ${Group} {
            margin-top: 10px;
            align-items: center;
            margin-right: 0;
        }

        ${Button} {
            margin: 15px auto 0;
            text-align: center;
            width: 100%;
            height: auto;
        }
    }
`;
