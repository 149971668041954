import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useModal } from '../../providers/ModalProvider';
import CheckEmailModal from '../../components/modals/CheckEmailModal';
import { cancelSubscriptionById, getSubscriptions } from '../../services/requests';
import HeaderImage from '../../components/HeaderImage';
import DotsSpinner from '../../components/DotsSpinner';
import useAppContext from '../../providers/AppContext/AppContext.hooks';

import Subscription from './components/Subscription';
import NotSubscriptions from './NotSubscriptions';
import * as S from './Subscriptions.styles';

function getTime(date) {
    return new Date(date.replace(' ', 'T')).getTime();
}

function Subscriptions() {
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [unSubscribeIdsInProcess, setUnSubscribeIdsInProcess] = useState([]);
    const [unSubscribedPlans, setUnsubscribedPlans] = useState([]);
    const { t } = useTranslation();
    const { appDomain } = useAppContext();

    const openModal = useModal();

    useEffect(() => {
        getSubscriptions(appDomain.domain, appDomain.appUrl.url)
            .then((response) => {
                setSubscriptions(response);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    function onUnSubscribe(subscription) {
        return () => {
            setUnSubscribeIdsInProcess([...unSubscribeIdsInProcess, subscription.id]);

            cancelSubscriptionById({
                id: subscription.id,
                sign: window.location.pathname.slice(1),
                code: appDomain.appUrl.apiPath,
                APP_URL: appDomain.appUrl.url,
            })
                .then(() => {
                    openModal(CheckEmailModal, {});
                    setUnsubscribedPlans([...unSubscribedPlans, subscription.id]);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setUnSubscribeIdsInProcess(unSubscribeIdsInProcess.filter((id) => id !== subscription.id));
                });
        };
    }

    return (
        <S.Screen className={subscriptions.length === 0 ? 'no-subscriptions' : ''}>
            {isLoading ? (
                <S.Spinner>
                    <DotsSpinner />
                </S.Spinner>
            ) : (
                <>
                    {subscriptions.length === 0 ? (
                        <NotSubscriptions />
                    ) : (
                        <S.Component>
                            <HeaderImage />
                            <S.Title>{t('subscriptions.hasSubscriptions.title')}</S.Title>
                            <S.Subscriptions>
                                {subscriptions
                                    .sort((a, b) => getTime(b.next) - getTime(a.next))
                                    .map((subscription, index) => (
                                        <Subscription
                                            key={index}
                                            isUnSubscribe={
                                                unSubscribedPlans.includes(subscription.id) ||
                                                Boolean(subscription.cancelled)
                                            }
                                            loading={unSubscribeIdsInProcess.includes(subscription.id)}
                                            onUnSubscribe={onUnSubscribe(subscription)}
                                            className="subscription-card"
                                            {...subscription}
                                        />
                                    ))}
                            </S.Subscriptions>
                            <S.BottomText
                                dangerouslySetInnerHTML={{
                                    __html: t('supportText').replace(
                                        '{{}}',
                                        `<a href="${appDomain.email.url}">${t('supportLinkText')}</a>`,
                                    ),
                                }}
                            />
                        </S.Component>
                    )}
                </>
            )}
        </S.Screen>
    );
}

export default Subscriptions;
