import MobileBgImage from '../../assets/images/main/bg_mobile_image1x.png';
import MobileBgImage2x from '../../assets/images/main/bg_mobile_image2x.png';
import PeopleImage from '../../assets/images/main/bg_image1x.png';
import PeopleImage2x from '../../assets/images/main/bg_image2x.png';
import MobileBgImageWebP from '../../assets/images/main/webP/mobile-main-bg.webp';
import PeopleImageWebP from '../../assets/images/main/webP/bg_image1x.webp';
import PeopleImageWebP2x from '../../assets/images/main/webP/bg_image2x.webp';
import { ContentContainer } from '../../components/ContentContainer';
import { getImageByQueryParams } from '../../utils/common';
import useTrackInitialLoad from '../../hooks/loggerHooks/useTrackInitialLoad';
import { EV } from '../../services/analytics/analyticEvents/appyCntEvents';
import { useTrackEvent } from '../../hooks/loggerHooks/useTrackEvent';
import AppLogo from '../../components/AppLogo';

import Main from './components/Main';
import Footer from './components/Footer';
import { Background } from './Landing.styles';
import * as S from './Landing.styles';

function Landing({ onNextStep }) {
    const mobileBgImage = getImageByQueryParams(MobileBgImage, MobileBgImageWebP);
    const mobileBgImage2x = getImageByQueryParams(MobileBgImage2x, MobileBgImageWebP);
    const peopleImage = getImageByQueryParams(PeopleImage, PeopleImageWebP);
    const peopleImage2x = getImageByQueryParams(PeopleImage2x, PeopleImageWebP2x);

    const trackEvent = useTrackEvent();

    useTrackInitialLoad();

    function onClick() {
        trackEvent(EV.STEP, '0 - Start');
        onNextStep();
    }

    return (
        <>
            <Background>
                <picture>
                    <source srcSet={`${mobileBgImage} 1x, ${mobileBgImage2x} 2x`} media="(max-width: 1024px)" />
                    <source srcSet={`${peopleImage} 1x, ${peopleImage2x} 2x`} media="(min-width: 1024px)" />
                    <S.PeopleImage src={peopleImage} />
                </picture>
            </Background>
            <S.Component>
                <ContentContainer>
                    <S.Inner>
                        <S.Header>
                            <AppLogo />
                        </S.Header>
                        <Main onClick={onClick} />
                        <Footer />
                    </S.Inner>
                </ContentContainer>
            </S.Component>
        </>
    );
}

export default Landing;
