import { useEffect } from 'react';

function useScrollPolyfill() {
    useEffect(() => {
        (async function () {
            try {
                if (!('scrollBehavior' in document.documentElement.style)) {
                    await import('scroll-behavior-polyfill');
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);
}

export default useScrollPolyfill;
