import * as S from './Step.styles';

function Step({ index, title, children, ...rest }) {
    return (
        <S.Component {...rest}>
            <S.Row>
                <S.Index>{index}</S.Index>
                <S.Title>{title}</S.Title>
            </S.Row>
            <S.Content>{children}</S.Content>
        </S.Component>
    );
}

export default Step;
