import { useContext } from 'react';

import { CurrencyContext } from './CurrencyContext';

function useCurrency() {
    const context = useContext(CurrencyContext);

    return {
        currency: context.currency,
        setCurrency: context.setCurrency,
        currencyRate: context.currencyRate,
        setCurrencyRate: context.setCurrencyRate,
    };
}

export default useCurrency;
