import { useTranslation } from 'react-i18next';

import SunMobImage1x from '../../assets/images/sun/sun-desktop-1x.png';
import SunMobImage2x from '../../assets/images/sun/sun-desktop-2x.png';
import SunMobImageW1x from '../../assets/images/sun/sun-desktop-1x.webp';
import SunMobImageW2x from '../../assets/images/sun/sun-desktop-2x.webp';
import SunDeskImage1x from '../../assets/images/sun/sun-desktop-full-1x.png';
import SunDeskImage2x from '../../assets/images/sun/sun-desktop-full-2x.png';
import SunDeskImageW1x from '../../assets/images/sun/sun-desktop-full-1x.webp';
import SunDeskImageW2x from '../../assets/images/sun/sun-desktop-full-2x.webp';
import useAppContext from '../../providers/AppContext/AppContext.hooks';

import * as S from './Subscriptions.styles';

function NotSubscriptions() {
    const { t } = useTranslation();
    const { appDomain } = useAppContext();
    return (
        <S.Component className="no-subscriptions">
            <S.HeaderImageWrapper>
                <picture>
                    <source
                        srcSet={`${SunDeskImage1x} 1x, ${SunDeskImage2x} 2x`}
                        media="(min-width: 768px)"
                        type={'image/png'}
                    />
                    <source
                        srcSet={`${SunDeskImageW1x} 1x, ${SunDeskImageW2x} 2x`}
                        media="(min-width: 768px)"
                        type={'image/webp'}
                    />
                    <source
                        srcSet={`${SunMobImage1x} 1x, ${SunMobImage2x} 2x`}
                        media="(max-width: 768px)"
                        type={'image/png'}
                    />
                    <source
                        srcSet={`${SunMobImageW1x} 1x, ${SunMobImageW2x} 2x`}
                        media="(max-width: 768px)"
                        type={'image/webp'}
                    />
                    <img src={SunMobImageW1x} alt={'sun-header'} />
                </picture>
            </S.HeaderImageWrapper>
            <S.Title>{t('subscriptions.noSubscriptions.title')}</S.Title>
            <S.SubTitle>{t('subscriptions.noSubscriptions.subTitle')}</S.SubTitle>
            <S.BottomText
                dangerouslySetInnerHTML={{
                    __html: t('supportText').replace(
                        '{{}}',
                        `<a href="${appDomain.email.url}">${t('supportLinkText')}</a>`,
                    ),
                }}
                className="no-subscriptions"
            />
        </S.Component>
    );
}

export default NotSubscriptions;
