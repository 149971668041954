import { createContext, useMemo, useRef, useState } from 'react';

import { useRecurly } from '@recurly/react-recurly';
import { useTranslation } from 'react-i18next';

import useUpdateEffect from '../../hooks/utilHooks/useUpdateEffect';
import {
    activateSubscriptionRecurly,
    createSubscriptionNoUser,
    createSubscriptionRecurly,
} from '../../services/requests';
import useAttributes from '../../hooks/loggerHooks/useAttribution';
import useAuthorization from '../AuthorizationContext/AuthorizationContext.hooks';
import usePaymentConfig from '../PaymentConfigContext/PaymentConfigContext.hooks';
import useAppContext from '../AppContext/AppContext.hooks';
import { PRELOADER_SIGN_IN_KEY, QUIZ_STEP_KEY, QUIZ_STEPS_MAP } from '../../constants/quiz';
import { getEventFromURLByKey } from '../../services/events';
import { EMAIL_REGEXP } from '../../constants/common';
import {
    getActiveSubscriptionConfig,
    getPaymentParamsFromSessionStorage,
    setPaymentParamsToSessionStorage,
} from '../../services/payment';
import useLogger from '../../hooks/loggerHooks/useLogger';
import usePaymentEmail from '../PaymentEmailContext/PaymentEmailContext.hooks';
import { PixelEvents } from '../../services/analytics/analyticEvents/pixelStandardEvents';
import { EV } from '../../services/analytics/analyticEvents/appyCntEvents';
import { useTrackEvent } from '../../hooks/loggerHooks/useTrackEvent';
import { pixelTrackStandard } from '../../services/analytics/fPixel';
import { convertStringToBoolean, removeRedirectParamsFromQuery } from '../../utils/common';
import { handleRedirectBackAfterPayment, handleRedirectToPaymentPage, isShouldRedirectForPayment } from 'src/utils/redirectToPayment';

export const ContextPayByCardMethod = createContext(null);

function PayByCardContext({ children }) {
    const { t } = useTranslation();
    const trickEvent = convertStringToBoolean(getEventFromURLByKey('event_trick', 'false'));
    const trickFinalValue = trickEvent ? PixelEvents.Subscribe : PixelEvents.Purchase;
    const activateSubscriptionConfig = getActiveSubscriptionConfig();
    const { user } = useAuthorization();
    const { setStep, setIsOpenSignInPopUp } = useAppContext();
    const { trackAppyCntEvent } = useLogger();
    const trackEvent = useTrackEvent();

    const { paymentEmail } = usePaymentEmail();

    const userId = user?.uid;
    const email = user?.profile?.email ?? user?.email;

    const [isLoader, setLoader] = useState(false);

    const { paymentConfig } = usePaymentConfig();
    const recurly = useRecurly();
    const { attribution } = useAttributes();

    const [subscriptionId, setSubscriptionId] = useState('');

    const [error, setError] = useState('');

    const formRef = useRef(null);
    const errorRef = useRef(null);
    const recurlySecureRef = useRef(null);

    const selectedPlan = paymentConfig?.selectedPlan;

    const executeScroll = () => errorRef.current?.scrollIntoView({ behavior: 'smooth' });

    const eventParams = {
        value: selectedPlan?.trial_sum ?? selectedPlan?.sum,
        currency: 'USD',
        code: selectedPlan?.id ?? '',
    };

    const shouldRedirect = useMemo(() => {
        const hasRedirectDomain = new URLSearchParams(window.location.search).get('redirectDomain');

        const isOnRightDomain =
            window.location.origin.includes('billing-tracker.com') || window.location.origin.includes('localhost');

        const result = hasRedirectDomain !== null && isOnRightDomain;
        return result;
    }, []);

    useUpdateEffect(() => {
        const cardTokenId = new URLSearchParams(window.location.search).get('redirectCardTokenId');
        if (cardTokenId !== null && selectedPlan) {
            createSubscription(cardTokenId);
        }
    }, [selectedPlan]);

    function createSubscription(tokenId) {
        if (activateSubscriptionConfig || subscriptionId.trim()) return;
        const redirectUserEmail = new URLSearchParams(window.location.search).get('redirectUserEmail');

        createSubscriptionNoUser({
            url: paymentConfig?.recurly.uri ?? '',
            email: redirectUserEmail ?? paymentEmail,
            plan_id: selectedPlan?.id ?? '',
            token_id: tokenId,
            attribution: attribution,
        })
            .then((response) => {
                if (response.error) {
                    if (isShouldRedirectForPayment) {
                        handleRedirectToPaymentPage(response.error);
                    }
                    trackAppyCntEvent('Subscription Error', trickFinalValue, eventParams).catch((e) =>
                        console.error(e),
                    );

                    setLoader(false);
                    setError(response.error);
                    executeScroll();
                    return;
                }

                if (response.id) {
                    setPaymentParamsToSessionStorage({
                        url: paymentConfig?.recurly.uri ?? '',
                        selectedPlanId: selectedPlan.id,
                        subscriptionId: response.id,
                        attribution: JSON.stringify(attribution),
                    });

                    setSubscriptionId(response.id);
                    setIsOpenSignInPopUp(true);

                    trackEvent(
                        EV[trickFinalValue.toUpperCase()],
                        undefined,
                        EV[trickFinalValue.toUpperCase()],
                        eventParams,
                    );
                    pixelTrackStandard(trickFinalValue, eventParams);
                }
            })
            .catch((e) => {
                trackAppyCntEvent('Subscription Error', trickFinalValue, eventParams).catch((e) => console.error(e));
                setError(String(e));
            })
            .finally(() => {
                setLoader(false);
            });
    }

    useUpdateEffect(() => {
        const isAppleAuth = user?.providerId === 'apple.com';

        const sessionPaymentParams = getPaymentParamsFromSessionStorage([
            'url',
            'subscriptionId',
            'selectedPlanId',
            'attribution',
        ]);

        const currentSubscriptionId = sessionPaymentParams.subscriptionId ?? subscriptionId;

        if (activateSubscriptionConfig && user && !isAppleAuth) {
            setLoader(true);

            activateSubscriptionRecurly({
                url: sessionPaymentParams.url ?? paymentConfig?.recurly.uri ?? '',
                email,
                firebase_id: userId,
                apple_token: undefined,
                account_code: activateSubscriptionConfig.account_code,
            })
                .then((response) => {
                    if (response.error) {
                        console.error(error);
                        setError(response.error);
                    } else if (!response.success) {
                        setError('The subscription has been tied to another provider. Try another option');
                    } else {
                        setStep(QUIZ_STEPS_MAP.MANUAL);
                    }
                })
                .catch((e) => {
                    console.error('[Pay By Card subscriptionActivate error]: ', e);
                    setError(e.toString());
                })
                .finally(() => {
                    setPaymentParamsToSessionStorage({
                        [PRELOADER_SIGN_IN_KEY]: '',
                    });
                    setLoader(false);
                });
        } else if (userId && currentSubscriptionId !== '' && !isAppleAuth) {
            setLoader(true);

            createSubscriptionRecurly({
                url: sessionPaymentParams.url ?? paymentConfig?.recurly.uri ?? '',
                plan_id: sessionPaymentParams.selectedPlanId ?? selectedPlan?.id ?? '',
                subscription_id: currentSubscriptionId,
                email: email ?? '',
                firebase_id: user.uid,
                apple_token: undefined,
                attribution: JSON.parse(sessionPaymentParams.attribution) ?? attribution,
            })
                .then((response) => {
                    if (response.error) {
                        setError(response.error);
                        executeScroll();
                        return;
                    }

                    if (response.actionTokenId) {
                        const risk = recurly.Risk();

                        const threeDSecure = risk.ThreeDSecure({
                            actionTokenId: response.actionTokenId,
                        });

                        threeDSecure.on('token', function (token) {
                            createSubscription(token.id);
                        });

                        threeDSecure.on('error', function (error) {
                            console.error('error', error);
                        });

                        threeDSecure.attach(recurlySecureRef.current);

                        return;
                    }

                    setPaymentParamsToSessionStorage({
                        subscriptionId: '',
                        [QUIZ_STEP_KEY]: '',
                    });
                    setSubscriptionId('');
                    setStep(QUIZ_STEPS_MAP.MANUAL);
                })
                .catch((error) => {
                    console.error(error.body.error);
                    setError(error.body.error);
                    executeScroll();
                })
                .finally(() => {
                    setPaymentParamsToSessionStorage({
                        [PRELOADER_SIGN_IN_KEY]: '',
                    });
                    setLoader(false);
                    removeRedirectParamsFromQuery();
                });
        }
    }, [subscriptionId, user]);

    function handleClickByRecurlyButton(event) {
        if (isLoader) return;

        event.preventDefault();
        setError('');
        setLoader(true);

        if (!EMAIL_REGEXP.test(paymentEmail)) {
            setError(t('errors.Email_field_is_not_valid'));
            setLoader(false);
        } else {
            trackAppyCntEvent('payByCardBeforeToken', trickFinalValue, eventParams).catch((e) => console.error(e));

            recurly.token(formRef.current, async (err, token) => {
                if (err) {
                    trackAppyCntEvent('payByCardAfterTokenError', trickFinalValue, eventParams).catch((e) =>
                        console.error(e),
                    );

                    const errors = {
                        cvv: t('errors.CVV_must_be_three_digits'),
                    };

                    setError(errors[err.fields[0]] || err.message);
                    executeScroll();
                    setLoader(false);
                } else {
                    trackAppyCntEvent('payByCardAfterToken', trickFinalValue, eventParams).catch((e) =>
                        console.error(e),
                    );

                    if (shouldRedirect) {
                        handleRedirectBackAfterPayment(token.id, paymentEmail);
                    } else {
                        createSubscription(token.id);
                    }
                }
            });
        }
    }

    const value = {
        handleClickButton: handleClickByRecurlyButton,
        formRef: formRef,
        recurlySecureRef: recurlySecureRef,
        errorRef: errorRef,
        isLoader: isLoader,
        setError,
        error: error,
    };

    return <ContextPayByCardMethod.Provider value={value}>{children}</ContextPayByCardMethod.Provider>;
}

export default PayByCardContext;
