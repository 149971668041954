import { createContext, useEffect, useLayoutEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import useApplePay from '../ApplePayContext/ApplePayContext.hooks';
import usePayByCard from '../PayByCardContext/PayByCardContext.hooks';
import usePaymentConfig from '../PaymentConfigContext/PaymentConfigContext.hooks';

export const ErrorContext = createContext(null);

function ErrorProvider({ children }) {
    const { error: errorApplePay, setError: setApplePayError } = useApplePay();
    const { error: errorPayByCard, setError: setPayByCardError } = usePayByCard();
    const { paymentConfig } = usePaymentConfig();

    const [error, setError] = useState(errorApplePay || errorPayByCard);

    useLayoutEffect(() => {
        if (paymentConfig?.isSinglePaymentMode) return;

        setError(errorApplePay || errorPayByCard);
    }, [errorApplePay, errorPayByCard, paymentConfig]);

    useEffect(() => {
        if (paymentConfig?.isSinglePaymentMode) return;

        if (error.trim()) {
            setTimeout(() => {
                setApplePayError('');
                setPayByCardError('');
                setError('');
            }, 5000);
        }
    }, [error, paymentConfig?.isSinglePaymentMode, setApplePayError, setPayByCardError]);

    const value = { error, setError };

    return (
        <ErrorContext.Provider value={value}>
            <ErrorMessage isShowError={!!error}>{String(error)}</ErrorMessage>
            {children}
        </ErrorContext.Provider>
    );
}

export const ErrorMessage = styled.div`
    text-align: center;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-110%);
    word-break: break-all;
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 22px;
    max-width: 414px;
    width: 100vw;
    background: var(--error-color);
    min-height: 62px;
    height: auto;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 10001;
    color: white;
    transition: transform 0.2s ease-in-out;

    ${(props) =>
        props.isShowError &&
        css`
            transform: translateX(-50%) translateY(0);
        `}
`;

export default ErrorProvider;
