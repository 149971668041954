const daysShortForm = ['Sunday', 'Monday', 'Tuesday', 'Thursday', 'Wednesday', 'Friday', 'Saturday'];

const monthsLongForm = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export function getValidWord(value, type, i18nTranslate) {
    if (!value && !type) return '';
    value = Math.abs(value) % 100;

    const num = value % 10;

    if (value > 10 && value < 20) return i18nTranslate(`calendar.${type}.2`);

    if (num > 1 && num < 5) return i18nTranslate(`calendar.${type}.1`);

    if (num === 1) return i18nTranslate(`calendar.${type}.0`);

    return i18nTranslate(`calendar.${type}.2`);
}

export function convertedDate(date, i18nTranslate) {
    if (!date) return '';

    const dateAaArray = date.split(' ')[0].split('-');

    const dateString = dateAaArray.join('/');

    const newDate = new Date(dateString);

    const dayIndex = newDate.getDay();

    if (i18nTranslate) {
        const dayOfweek = daysShortForm[dayIndex];
        const month = i18nTranslate(`calendar.${monthsLongForm[parseInt(dateAaArray[1]) - 1]}`);

        return `${dateAaArray[2]} ${dayOfweek} ${month} ${dateAaArray[0]}`;
    }

    return `${dateAaArray[2]}${daysShortForm[dayIndex]} ${monthsLongForm[parseInt(dateAaArray[1]) - 1]} ${
        dateAaArray[0]
    }`;
}
