import i18n from 'i18next';
// import backend from "i18next-http-backend";
// import XHR from "i18next-xhr-backend";
import { initReactI18next } from 'react-i18next';

import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import koKR from './locales/ko-KR.json';
import ptBR from './locales/pt-BR.json';

const resources = {
    de: { translation: de },
    en: { translation: en },
    es: { translation: es },
    fr: { translation: fr },
    it: { translation: it },
    ko: { translation: koKR },
    pt: { translation: ptBR },
};
// .use(XHR)
i18n.use(initReactI18next)
    // .use(backend)
    .init({
        resources,
        lng: new URLSearchParams(window.location.search).get('locale')?.slice(0, 2) ?? navigator.language ?? 'en',
        fallbackLng: 'en',
        debug: false,
        cache: {
            enabled: true,
            prefix: 'i18next_translations_',
            expirationTime: 24 * 60 * 60 * 1000, //one day
        },

        // backend: {
        //     customHeaders: {
        //         Authorization:
        //             "Basic Z2V0Zml0X3NlcnZpY2VfYWNjb3VudDp6bjNMbWtaQWZkakZiTlBDYThyVg==",
        //     },
        //     loadPath: () => {
        //         const fileName = `instatrack_{{lng}}.json`;
        //         return `https://api.bitbucket.org/2.0/repositories/appyfurious_team/localizations/src/master/InstaTrack/${fileName}`;
        //     },
        // },
        saveMissing: true,
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;
