import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Skip first render
 * @param effect
 * @param deps
 */
const useUpdateEffect = (effect: EffectCallback, deps?: DependencyList): void => {
    const mounted = useRef(false);
    useEffect(() => {
        if (mounted.current) {
            effect();
        } else {
            mounted.current = true;
        }
    }, deps);
};

export default useUpdateEffect;
