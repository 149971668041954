import { createContext, useRef, useState } from 'react';

import { findInstagramUserByName, findSimilarUsersByName } from '../../services/requests';

export const Context = createContext(null);

/** User Model **/
/**
 "is_private": boolean,
 "full_name": string,
 "follower_count": number,
 "following_count": number,
 "profle_pic": string,
 "notFollowingBackCount": number,
 "notFollowingBack": object
 **/

function InstagramContext({ children }) {
    const [instagramUser, setInstagramUser] = useState(null);

    const [loading, setLoading] = useState(false);
    const [notFoundUser, setNotFoundUser] = useState(false);
    const [statusCode, setStatusCode] = useState(null);

    /** Similar Users**/

    const [similarUsers, setSimilarUsers] = useState([]);
    const [isFetchingSimilarUsers, setIsFetchingSimilarUsers] = useState(false);
    const [isNotFountSimilarUsers, setIsNotFountSimilarUsers] = useState(false);
    const abortController = useRef();
    const findUserAbortController = useRef();

    function findUserByLogin(userName) {
        setLoading(true);

        findUserAbortController.current = new AbortController();

        const id = setTimeout(() => {
            if (abortController.current) {
                abortController.current.abort();
            }
            if (findUserAbortController.current) {
                findUserAbortController.current.abort();
            }
        }, 7000);

        findInstagramUserByName(userName, findUserAbortController.current.signal)
            .then(async (response) => {
                if (response.status !== 200) {
                    throw new Error(`${response.status}`);
                } else {
                    const user = await response.json();

                    if (user.is_private) {
                        setStatusCode(201);
                    } else {
                        setStatusCode(200);
                    }

                    setInstagramUser({ ...user, userName });
                }
            })
            .catch((errorStatus) => {
                setStatusCode(Number(errorStatus.message));
                setNotFoundUser(true);
            })
            .finally(() => {
                setLoading(false);
                clearTimeout(id);
            });
    }

    function findSimilarUsersByLogin(userName) {
        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        setIsFetchingSimilarUsers(true);
        setIsNotFountSimilarUsers(false);

        findSimilarUsersByName(userName, abortController.current.signal)
            .then((response) => {
                const similarUsers = response.result;

                if (similarUsers.length === 0) {
                    setIsNotFountSimilarUsers(true);
                } else {
                    setSimilarUsers(similarUsers);
                }

                setIsFetchingSimilarUsers(false);
            })
            .catch((error) => {
                if (error.name !== 'AbortError') {
                    setIsFetchingSimilarUsers(false);
                }
            });
    }

    const value = {
        user: instagramUser,
        similarUsers,
        isFetchingSimilarUsers,
        setIsFetchingSimilarUsers,
        findSimilarUsersByLogin,
        isLoadingUser: loading,
        statusCode: statusCode,
        notFoundUser,
        findUserById: findUserByLogin,
        setSimilarUsers,
        isNotFountSimilarUsers,
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default InstagramContext;
