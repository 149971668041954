import styled from 'styled-components';

import Button from '../../../../components/Button';

export const Title = styled.h1`
    margin: 40px 0 0;
    font-weight: bold;
    font-size: 56px;
    line-height: 76px;
`;

export const SubTitle = styled.span`
    margin-top: 30px;
    font-weight: normal;
    font-size: 22px;
    line-height: 36px;
`;

export const StyledButton = styled(Button)`
    max-width: 278px;
    margin-top: 40px;
`;

export const Content = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 660px;
    width: 100%;
    color: var(--textPrimary-color);
    top: -65px;

    @media (max-width: 1024px) {
        margin-top: 20px !important;
        max-width: 100%;
        top: 0;

        ${StyledButton} {
            margin-top: 20px;
            max-width: 100%;
            padding-right: 43px;

            svg {
                display: none;
            }
        }

        ${Title} {
            margin: 0;
            font-size: 28px;
            line-height: 38px;
            text-align: center;
        }

        ${SubTitle} {
            margin-top: 10px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
        }
    }

    @media (max-height: 800px) {
        top: 0;
    }
`;
