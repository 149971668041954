export const getFooterLinks = (t, email, domain) => [
    {
        label: t('landing.footer.links.0'),
        url: '/' + domain + '/terms.html',
        target: '_blank',
    },
    {
        label: t('landing.footer.links.1'),
        url: '/' + domain + '/privacy.html',
        target: '_blank',
    },
    {
        label: email.label,
        url: email.url,
        target: undefined,
    },
];
