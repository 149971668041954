import styled, { css, keyframes } from 'styled-components';

export function Overlay({ className, children }) {
    return <OverlayBlock className={className}>{children}</OverlayBlock>;
}

function Spinner({ className, color = 'white', absolute = true, text }) {
    return (
        <Container className={className} absolute={absolute}>
            <Circle color={color} />
            {text ? <StatusText>{text}</StatusText> : null}
        </Container>
    );
}

const spin = keyframes`
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
`;

const colorMap = {
    black: 'black',
    white: 'white',
};

const Circle = styled.div`
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border: 4px solid #ccc;
    border-top: 4px solid ${(props) => colorMap[props.color]};
    border-radius: 50%;
    animation: ${spin} 1s infinite linear;
`;

const Container = styled.div`
    position: relative;
    z-index: 5;

    ${(props) =>
        props.absolute
            ? css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
              `
            : ''};
`;

const StatusText = styled.span`
    display: block;
    font-weight: bold;
    margin-top: 10px;
`;

const OverlayBlock = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
    z-index: 10;
`;

export default Spinner;
