import { JSXElementConstructor, PropsWithChildren, ReactElement, ReactNode } from 'react';

interface ProviderComposerProps {
    components: Array<JSXElementConstructor<PropsWithChildren<any>>>;
    children: ReactNode;
}

/**
 * Compose multiple providers together
 */
const ProviderComposer = ({ components = [], children }: ProviderComposerProps): ReactElement => (
    <>
        {components.reduceRight(
            (acc, Comp) => (
                <Comp>{acc}</Comp>
            ),
            children,
        )}
    </>
);

export default ProviderComposer;
