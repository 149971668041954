import { useEffect, useRef } from 'react';

import styled from 'styled-components';

function Overlay({ onClick, hidden, scrollLockDisabled, ...rest }) {
    const ref = useRef(null);

    useEffect(() => {
        if (scrollLockDisabled) return;

        if (!hidden) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [hidden, scrollLockDisabled]);

    function handleClick(event) {
        if (event.target === event.currentTarget) {
            onClick();
        }
    }

    return <Container ref={ref} onClick={handleClick} hidden={hidden} {...rest} />;
}

export const PaymentModalOverlay = styled(Overlay)`
    touch-action: pan-x pan-y !important;
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    overflow: auto !important;
    padding: 30px 0 !important;
`;

const Container = styled.div`
    touch-action: pan-x pan-y;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    position: fixed;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    &[hidden] {
        display: none;
    }
`;

export default Overlay;
