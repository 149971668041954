import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    transform: translateY(-30px) scale(1.01);
  }

  50% {
    transform: translateY(0) scale(0.70);
  }

  100% {
    transform: translateY(-30px) scale(1.01);
  }
`;

export const Component = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 120px;
    height: 70px;

    div {
        top: 33px;
        width: 21px;
        height: 21px;

        border-radius: 50%;
        background: var(--primary-color);
        transform: translateY(-30px) scale(1.01);
    }

    div:nth-child(1) {
        animation: ${animation} 1.4s infinite;
        animation-timing-function: ease-in-out;
    }

    div:nth-child(2) {
        animation: ${animation} 1.4s infinite;
        animation-delay: 0.1s;
        animation-timing-function: ease-in-out;
    }

    div:nth-child(3) {
        animation: ${animation} 1.4s infinite;
        animation-delay: 0.2s;
        animation-timing-function: ease-in-out;
    }
`;
