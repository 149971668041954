import styled from 'styled-components';

import Button from '../../Button';

export const Component = styled.div`
    max-width: 380px;
    width: 100%;
    padding: 0 20px;
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px 24px;
    background: #ffffff;
    border-radius: 16px;
`;

export const Title = styled.span`
    margin-top: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: black;
    text-align: center;
`;

export const SubTitle = styled.span`
    margin-top: 5px;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #808080;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 24px;
    padding-top: 17px;
    padding-bottom: 17px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: white;
    background: var(--primary-color);
`;
