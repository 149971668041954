export function isIos() {
    return /iphone|ipad|ipod|mac|macintosh/gi.test(window.navigator.userAgent.toLowerCase());
}

export function filterFalsyValues(object) {
    try {
        const keys = Object.keys(object);
        keys.forEach((key) => {
            if (object[key] == null || object[key] === '') {
                delete object[key];
            }
        });
        return object;
    } catch (e) {
        console.error('[removeFalsyValuesFromObject error]: ', e);
    }
    return object;
}

export function getOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    if (macosPlatforms.indexOf(platform) !== -1) {
        return 'mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        return 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'windows';
    } else if (/Android/.test(userAgent)) {
        return 'android';
    } else if (/Linux/.test(platform)) {
        return 'linux';
    }
    return 'other';
}

export function dividePathnameAndSearch(url) {
    const questionMarkIndex = url.indexOf('?');
    if (questionMarkIndex === -1) {
        return [url, ''];
    } else {
        const pathname = url.slice(0, questionMarkIndex);
        const search = url.slice(questionMarkIndex);

        return [pathname, search];
    }
}

export function convertStringToBoolean(incomeString) {
    switch (incomeString.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
            return true;
        case 'false':
        case 'no':
        case '0':
        case null:
            return false;
        default:
            return false;
    }
}

export function mergeSearchParamsIntoUrl(currentUrl, params) {
    const [pathname, search] = dividePathnameAndSearch(currentUrl);
    const searchParams = new URLSearchParams(search);

    if (params instanceof URLSearchParams) {
        params.forEach((key, value) => {
            searchParams.set(key, value);
        });
    } else {
        Object.keys(params).forEach((key) => {
            const value = params[key];

            if (value === null || value === undefined) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, String(value));
            }
        });
    }

    return [pathname, searchParams.toString()].filter(Boolean).join('?');
}

export function scrollToElementById({ yOffset = -80, id, behavior = 'smooth' }) {
    const element = document.getElementById(id);

    if (!element) return;

    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scroll({
        top: y,
        behavior: behavior,
    });
}

export function getImageByQueryParams(pngImage, webImage) {
    const params = new URLSearchParams(document.location.search);

    const imageFormat = params.get('imageFormat');

    return imageFormat === 'webP' ? webImage : pngImage;
}

export function isShowLayoutByParameter(descriptionPlan) {
    switch (descriptionPlan) {
        case '0':
        case 'hidden':
        case 'false':
            return false;

        default:
            return true;
    }
}

export function canUseDOM() {
    try {
        return (
            typeof window !== 'undefined' &&
            typeof window.document !== 'undefined' &&
            typeof window.document.createElement !== 'undefined'
        );
    } catch (e) {
        console.error('[canUseDOM error]: ', e);
    }
    return false;
}

export function isBrowser() {
    return typeof window !== 'undefined';
}

function detectBrowserByUserAgent(matchString) {
    try {
        if (isBrowser()) {
            return window.navigator.userAgent.includes(matchString);
        }
    } catch (e) {
        console.error(e);
    }
    return false;
}

export const isChrome = detectBrowserByUserAgent('CriOS');

/**
 * Check if Instagram webview browser
 */
export const isInstagram = detectBrowserByUserAgent('Instagram');

/**
 * Check if Facebook webview browser
 */
export const isFacebook = detectBrowserByUserAgent('FBAN') || detectBrowserByUserAgent('FBAV');

/**
 * Detects both Facebook & Instagram in-app browsers
 */
export const isMetaInAppBrowser = isFacebook || isInstagram;

const detectIOSVersion = (version) => {
    try {
        if (isBrowser()) {
            const userAgent = navigator.userAgent || navigator.vendor;
            if (/iPad|iPhone|iPod|Macintosh|ios|Mac/i.test(userAgent) && !window.MSStream) {
                let IOSVersion = (navigator.userAgent.match(/\b[0-9]+_[0-9]+(?:_[0-9]+)?\b/) || [''])[0].split('_')[0];
                return Number(IOSVersion) <= version;
            }
        }
        return false;
    } catch (e) {
        console.error('Could not detect IOS version');
        return false;
    }
};

export const isIOS14Check = detectIOSVersion(14);


/**
 * Remove params that uses to pay on huracan
 */

export function removeRedirectParamsFromQuery() {
    let paramsString = window.location.search;
    let searchParams = new URLSearchParams(paramsString);

    searchParams.delete('redirectCardTokenId');
    searchParams.delete('redirectAppleTokenId');
    searchParams.delete('redirectUserEmail');
    searchParams.delete('__page');

    if (window.history.replaceState !== undefined) {
        let searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
        let newUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            searchString +
            window.location.hash;
            window.history.replaceState(null, '', newUrl);
    }
}
