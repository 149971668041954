import * as S from './DotsSpinner.styles';

function DotsSpinner() {
    return (
        <S.Component id="DotsSpinner">
            <div></div>
            <div></div>
            <div></div>
        </S.Component>
    );
}

export default DotsSpinner;
