import currencies from '../model/typings/currencies.json';

/**
 * Convert HTML symbol to string
 * @param str
 */
function decodeHTML(str) {
    return str.replace(/&#([0-9]+);/g, function (full, int) {
        return String.fromCharCode(parseInt(int));
    });
}

const currencyConverter = (amount, rate, currencySymbolPosition) => {
    try {
        let result = '';

        /**
         *  Find currency by Country from query locale
         */
        const currencySymbolRules = currencies[rate.currencyCode];

        /**
         *  Trim symbols to certain value {@param currencySymbolRules.units} after delimiter
         */

        const trimmedString = (Number(amount) * Number(rate.rate)).toFixed(currencySymbolRules.units);

        /**
         * Add currency symbol {@param currencySymbolRules.html} to result string
         */

        if (currencySymbolPosition === 'none') {
            result = trimmedString;
        } else if (currencySymbolPosition === 'left' || currencySymbolRules.left) {
            result = currencySymbolRules.html + (currencySymbolRules.space ? ' ' : '') + trimmedString;
        } else {
            result = trimmedString + (currencySymbolRules.space ? ' ' : '') + currencySymbolRules.html;
        }

        return result;
    } catch (e) {
        console.error('Could not convert units', e);
        return '';
    }
};

export default currencyConverter;
