import { lazy, Suspense, useLayoutEffect } from 'react';

import { QUIZ_STEPS_MAP } from '../../constants/quiz';
import useAppContext from '../../providers/AppContext/AppContext.hooks';
import Landing from '../Landing';
import { useRedirectToWeb2App } from '../../hooks/utilHooks/useRedirectToWeb2App';

import * as S from './Quiz.styles';

const Introduction = lazy(() => import('./components/Introduction'));
const AnalysisProcess = lazy(() => import('./components/AnalysisProcess'));
const Preview = lazy(() => import('./components/Preview'));
const Subscription = lazy(() => import('./components/Subscription'));
const Manual = lazy(() => import('./components/Manual'));

function Quiz({ isOpenSignInPopUp }) {
    const { isShowLanding } = useRedirectToWeb2App({ showLanding: isOpenSignInPopUp });
    const { step, setStep, setIsOpenSignInPopUp } = useAppContext();

    const subscriptionScreenMode = new URLSearchParams(document.location.search).get('ss');

    switch (new URLSearchParams(document.location.search).get('__page')) {
        case 'SIGNIN':
            isOpenSignInPopUp = true;
            break;
        case 'SUBSCRIPTION':
            setStep(QUIZ_STEPS_MAP.SUBSCRIPTION);
            break;
    }

    useLayoutEffect(() => {
        if (isOpenSignInPopUp) {
            /* eslint-disable */
            setStep(QUIZ_STEPS_MAP.SUBSCRIPTION);
            setIsOpenSignInPopUp(true);
        }
    }, []);

    function onNextStep(value) {
        return () => {
            setStep(value);
        };
    }

    function renderStep() {
        switch (step) {
            case QUIZ_STEPS_MAP.INTRODUCTION:
                return <Introduction onNextStep={onNextStep(QUIZ_STEPS_MAP.ANALYSIS)} />;

            case QUIZ_STEPS_MAP.ANALYSIS:
                return (
                    <AnalysisProcess
                        toSubscriptionStep={onNextStep(QUIZ_STEPS_MAP.SUBSCRIPTION)}
                        onNextStep={onNextStep(
                            subscriptionScreenMode ? QUIZ_STEPS_MAP.SUBSCRIPTION : QUIZ_STEPS_MAP.PREVIEW,
                        )}
                    />
                );

            case QUIZ_STEPS_MAP.PREVIEW:
                return <Preview onNextStep={onNextStep(QUIZ_STEPS_MAP.SUBSCRIPTION)} />;

            case QUIZ_STEPS_MAP.SUBSCRIPTION:
                return <Subscription />;

            case QUIZ_STEPS_MAP.MANUAL:
                return <Manual />;
            default:
                return null;
        }
    }

    if (!isShowLanding) {
        return <div />;
    }

    if (step === QUIZ_STEPS_MAP.LANDING) {
        return <Landing onNextStep={onNextStep(QUIZ_STEPS_MAP.INTRODUCTION)} />;
    }

    return (
        <S.Component>
            <S.Container>
                <S.Wrapper>
                    <Suspense fallback={<div />}>{renderStep()}</Suspense>
                </S.Wrapper>
            </S.Container>
        </S.Component>
    );
}

export default Quiz;
