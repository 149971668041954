import { useEffect } from 'react';

declare global {
    interface Window {
        preloadImages: Array<HTMLImageElement>;
    }
}

function usePreloadImages() {
    return;

    useEffect(() => {
        window.preloadImages = [
            '../../assets/images/steps/step_one/step_one1x.png',
            '../../assets/images/steps/step_one/step_one2x.png',
            '../../assets/images/steps/step_two/step_two1x.png',
            '../../assets/images/steps/step_two/step_two2x.png',
            '../../assets/images/steps/step_three/step_three1x.png',
            '../../assets/images/steps/step_three/step_three2x.png',
        ].map((image: string) => {
            const newImage = new Image();
            newImage.src = image;
            return newImage;
        });
    }, []);
}

export default usePreloadImages;
