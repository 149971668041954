import styled from 'styled-components';

export const ErrorMessage = styled.div`
    position: relative;
    color: red;
    display: ${(props) => (props.error ? 'flex' : 'none')};
    margin-top: ${(props) => (props.error ? '10px' : 0)};
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 16px;
`;

export const InputWrapper = styled.div`
    width: 100%;
    margin-top: 40px;
`;

export const Input = styled.input`
    width: 100%;
    border: 1px solid var(--gray100-color);
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--textPrimary-color);
    padding: 15px;
    background: transparent;

    &::placeholder {
        color: rgba(40, 38, 36, 0.3);
    }

    &:focus {
        &::placeholder {
            visibility: hidden;
        }
    }
`;
