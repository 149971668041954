import styled from 'styled-components';

export const AppWrapper = styled.div`
    position: relative;
    background: var(--bg-dark-color);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
`;
