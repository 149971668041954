import React, { StrictMode, Suspense } from 'react';

import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import './assets/css/index.css';

import App from './App';
import AttributionProvider from './providers/AttributionContext';
import PayByCardContext from './providers/PayByCardContext';
import PaymentConfigProvider from './providers/PaymentConfigContext/PaymentConfigContext';
import InstagramContext from './providers/InstagramContext';
import CurrencyProvider from './providers/CurrencyContext';
import AuthorizationProvider from './providers/AuthorizationContext';
import ApplePayProvider from './providers/ApplePayContext';
import AppContext from './providers/AppContext';
import PaymentEmailProvider from './providers/PaymentEmailContext';
import { ModalProvider } from './providers/ModalProvider';
import LoggerProvider from './providers/LoggerProvider';
import ErrorProvider from './providers/ErrorContext';
import i18n from './i18n';
import ProviderComposer from './components/ProviderComposer';
import InitScripts from './components/InitScripts';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <StrictMode>
        <Suspense fallback={<div style={{ background: 'var(--bg-color)' }} />}>
            <InitScripts />
            <I18nextProvider i18n={i18n}>
                <ProviderComposer
                    components={[
                        CurrencyProvider,
                        AppContext,
                        AttributionProvider,
                        LoggerProvider,
                        AuthorizationProvider,
                        PaymentEmailProvider,
                        PaymentConfigProvider,
                        ApplePayProvider,
                        PayByCardContext,
                        ErrorProvider,
                        ModalProvider,
                        InstagramContext,
                    ]}
                >
                    <App />
                </ProviderComposer>
            </I18nextProvider>
        </Suspense>
    </StrictMode>,
);
