import { forwardRef } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/svg/arrow.svg';
import Spinner, { Overlay } from '../Spinner';

import * as S from './Button.styles';
import { variantStyles } from './Button.constants';

const Button = forwardRef(({ variant = 'primary', children, loading, withIcon = false, ...rest }, ref) => {
    return (
        <S.Button id="Button" ref={ref} withIcon={withIcon} variantStyles={variantStyles[variant]} {...rest}>
            {loading ? (
                <>
                    {children}
                    <Overlay>
                        <Spinner absolute={true} />
                    </Overlay>
                </>
            ) : (
                <span>{children}</span>
            )}
            {withIcon && !loading && (
                <S.IconWrapper>
                    <ArrowIcon />
                </S.IconWrapper>
            )}
        </S.Button>
    );
});

export default Button;
