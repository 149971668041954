/**
 * Hook that tracks initial page load
 */
import { useEffect } from 'react';

import { EV } from '../../services/analytics/analyticEvents/appyCntEvents';
import { pixelTrackStandard } from '../../services/analytics/fPixel';
import { PixelEvents } from '../../services/analytics/analyticEvents/pixelStandardEvents';

import { useTrackEvent } from './useTrackEvent';
import useAttribution from './useAttribution';

const useTrackInitialLoad = () => {
    const trackEvent = useTrackEvent();
    const { isAttributesLoaded } = useAttribution();

    useEffect(() => {
        if (isAttributesLoaded) {
            trackEvent(EV.LOADED);
            pixelTrackStandard(PixelEvents.ViewContent);
        }
    }, [isAttributesLoaded]);
};

export default useTrackInitialLoad;
