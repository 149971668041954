import { createContext, createElement, useCallback, useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import Overlay from '../../components/Overlay';

export const ModalContextProvider = createContext(null);

function ModalProvider(props) {
    const [modal, setModal] = useState(null);
    const [isInnerContentVisible, setInnerContentVisible] = useState(false);

    const withAnimation = modal?.options?.withAnimation ?? props.withAnimation ?? true;

    const shouldCloseOnClickOutside =
        modal?.options?.shouldCloseOnClickOutside ?? props.shouldCloseOnClickOutside ?? true;

    useEffect(
        function showInnerContentIfModalOpen() {
            if (modal) {
                setInnerContentVisible(true);
            }
        },
        [modal],
    );

    const closeModal = useCallback(
        function hideInnerContent() {
            document.dispatchEvent(new Event('close-modal'));

            setInnerContentVisible(false);
            if (!withAnimation) {
                setModal(null);
            }
        },
        [withAnimation],
    );

    const openModal = useCallback(
        (...args) => {
            if (args[0] === null) {
                closeModal();

                return;
            }

            setModal({ type: args[0], props: args[1], options: args[2] });
        },
        [closeModal],
    );

    function handleTransitionEnd() {
        if (!isInnerContentVisible) {
            setModal(null);
        }
    }

    function handleOverlayClick() {
        if (shouldCloseOnClickOutside) {
            closeModal();
        }
    }

    const ModalOverlay = modal?.options?.components?.Overlay ?? props.components?.Overlay ?? Overlay;

    const scrollLockDisabled = modal?.options?.scrollLockDisabled ?? props.scrollLockDisabled ?? false;

    const isOpen = Boolean(modal);

    return (
        <ModalContextProvider.Provider value={openModal}>
            <ModalOverlay
                data-testid="modal-overlay"
                data-modal-overlay
                onClick={handleOverlayClick}
                hidden={!isOpen}
                scrollLockDisabled={scrollLockDisabled}
            >
                <ModalInner
                    isOpen={isInnerContentVisible}
                    withAnimation={withAnimation}
                    onTransitionEnd={handleTransitionEnd}
                >
                    {modal && modal.type !== null
                        ? createElement(modal.type, {
                              closeModal,
                              innerProps: modal.props,
                          })
                        : null}
                </ModalInner>
            </ModalOverlay>

            {props.children}
        </ModalContextProvider.Provider>
    );
}

const ModalInner = styled.div`
    transition: ${(props) => (props.withAnimation ? 'transform 0.35s ease-in-out, opacity 0.35s ease-in-out' : 'none')};

    ${(props) =>
        props.isOpen
            ? css`
                  transform: scale(1);
                  opacity: 1;
              `
            : css`
                  transform: scale(0);
                  opacity: 0;
              `}
`;

export default ModalProvider;
