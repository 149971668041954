export function initialSalePlans(salePlans, originPlans) {
    return salePlans.map((salePlan) => {
        const originPlan = originPlans.find((originPlan) => originPlan.id + '_sale' === salePlan.id);

        if (originPlan) {
            return {
                ...salePlan,
                oldPrice: originPlan.trial_sum ?? originPlan.sum,
            };
        } else {
            return {
                ...salePlan,
            };
        }
    });
}
