import Logo from '../assets/images/app-logo.png';
//import Logo2 from '../assets/images/followersreport.png';
import useAppContext from "../providers/AppContext/AppContext.hooks";

const AppLogo = () => {
    // @ts-ignore
    const { appDomain } = useAppContext();

    return <img src={appDomain.isFollowersreport ? Logo : Logo} alt={'logo'} width={80} height={80} />;
}

export default AppLogo;
