import { createContext, useEffect, useMemo, useState } from 'react';

import { useRecurly } from '@recurly/react-recurly';

import useUpdateEffect from '../../hooks/utilHooks/useUpdateEffect';
import {
    activateSubscriptionRecurly,
    createSubscriptionNoUser,
    createSubscriptionRecurly,
} from '../../services/requests';
import usePaymentConfig from '../PaymentConfigContext/PaymentConfigContext.hooks';
import useAttributes from '../../hooks/loggerHooks/useAttribution';
import useAuthorization from '../AuthorizationContext/AuthorizationContext.hooks';
import useAppContext from '../AppContext/AppContext.hooks';
import { PRELOADER_SIGN_IN_KEY, QUIZ_STEP_KEY, QUIZ_STEPS_MAP } from '../../constants/quiz';
import { getEventFromURLByKey } from '../../services/events';
import usePaymentEmail from '../PaymentEmailContext/PaymentEmailContext.hooks';
import {
    getActiveSubscriptionConfig,
    getPaymentParamsFromSessionStorage,
    setPaymentParamsToSessionStorage,
} from '../../services/payment';
import useLogger from '../../hooks/loggerHooks/useLogger';
import { pixelTrackStandard } from '../../services/analytics/fPixel';
import { PixelEvents } from '../../services/analytics/analyticEvents/pixelStandardEvents';
import { EV } from '../../services/analytics/analyticEvents/appyCntEvents';
import { useTrackEvent } from '../../hooks/loggerHooks/useTrackEvent';
import { convertStringToBoolean, removeRedirectParamsFromQuery } from '../../utils/common';
import { handleRedirectBackAfterPayment, handleRedirectToPaymentPage, isShouldRedirectForPayment } from 'src/utils/redirectToPayment';

export const ContextApplePayMethod = createContext(null);

function ApplePayProvider({ children }) {
    const trickEvent = convertStringToBoolean(getEventFromURLByKey('event_trick', 'false'));
    const trickFinalValue = trickEvent ? PixelEvents.Subscribe : PixelEvents.Purchase;

    const activateSubscriptionConfig = getActiveSubscriptionConfig();
    const { user } = useAuthorization();
    const { setStep, setIsOpenSignInPopUp } = useAppContext();
    const [isLoader, setLoader] = useState(false);
    const { paymentAppleEmail } = usePaymentEmail();
    const [subscriptionNoUserEmail, setSubscriptionNoUserEmail] = useState('');

    const { trackAppyCntEvent } = useLogger();
    const trackEvent = useTrackEvent();

    const email = user?.profile?.email ?? user?.email;

    const [subscriptionId, setSubscriptionId] = useState('');
    const [isReadyAppleButton, setReadyAppleButton] = useState(false);
    const [error, setError] = useState('');
    const [applePay, setApplePay] = useState(null);

    const { paymentConfig } = usePaymentConfig();
    const recurly = useRecurly();
    const { attribution } = useAttributes();

    const selectedPlan = paymentConfig?.selectedPlan;

    const shouldRedirect = useMemo(() => {
        const hasRedirectDomain = new URLSearchParams(window.location.search).get('redirectDomain');

        const isOnRightDomain =
            window.location.origin.includes('billing-tracker.com') || window.location.origin.includes('localhost');

        const result = hasRedirectDomain !== null && isOnRightDomain;
        return result;
    }, []);

    useEffect(() => {
        setApplePay(
            recurly.ApplePay({
                country: 'US',
                currency: 'USD',
                label: selectedPlan?.name_full ?? '',
                total: selectedPlan?.trial_sum ?? selectedPlan?.sum,
            }),
        );
    }, [paymentConfig]);

    const eventParams = {
        value: selectedPlan?.trial_sum ?? selectedPlan?.sum,
        currency: 'USD',
        code: selectedPlan?.id ?? '',
    };

    useEffect(() => {
        applePay?.ready(() => {
            setReadyAppleButton(true);
        });
    }, [applePay]);

    useUpdateEffect(() => {
        const appleTokenId = new URLSearchParams(window.location.search).get('redirectAppleTokenId');
        const redirectUserEmail = new URLSearchParams(window.location.search).get('redirectUserEmail');

        if (appleTokenId !== null && redirectUserEmail !== null && selectedPlan) {
            createSubscriptionNoUser({
                url: paymentConfig?.recurly.uri ?? '',
                email: redirectUserEmail,
                subscriptionNoUserEmail,
                plan_id: selectedPlan?.id ?? '',
                token_id: appleTokenId,
                attribution: attribution,
            })
                .then((response) => {
                    if (response.error) {
                        if (isShouldRedirectForPayment) {
                            handleRedirectToPaymentPage(response.error);
                        }
                        trackAppyCntEvent('Subscription Error', trickFinalValue, eventParams).catch((e) =>
                            console.error(e),
                        );
                        setLoader(false);
                        return;
                    }

                    if (response.id) {
                        setPaymentParamsToSessionStorage({
                            url: paymentConfig?.recurly.uri ?? '',
                            selectedPlanId: selectedPlan.id,
                            subscriptionId: response.id,
                            attribution: JSON.stringify(attribution),
                        });

                        trackEvent(
                            EV[trickFinalValue.toUpperCase()],
                            undefined,
                            EV[trickFinalValue.toUpperCase()],
                            eventParams,
                        );
                        pixelTrackStandard(trickFinalValue, eventParams);

                        setSubscriptionId(response.id);
                        setIsOpenSignInPopUp(true);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    trackAppyCntEvent('Subscription Error', trickFinalValue, eventParams).catch((e) =>
                        console.error(e),
                    );
                    setError(e.toString());
                    setLoader(false);
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    }, [selectedPlan]);

    useUpdateEffect(() => {
        const isNotValid =
            !applePay || subscriptionId.trim() || !subscriptionNoUserEmail.trim() || activateSubscriptionConfig;

        if (isNotValid) return;

        applePay.on('error', function (err) {
            console.error('Apple Pay error', err);
            setLoader(false);
        });

        applePay.on('token', function (token) {
            if (!token) {
                console.error('token is not defined');
            }

            if (isLoader) return;

            setLoader(true);

            if (shouldRedirect) {
                handleRedirectBackAfterPayment(token.id, subscriptionNoUserEmail);
            } else {
                createSubscriptionNoUser({
                    url: paymentConfig?.recurly.uri ?? '',
                    email: subscriptionNoUserEmail,
                    plan_id: selectedPlan?.id ?? '',
                    token_id: token.id,
                    attribution: attribution,
                })
                    .then((response) => {
                        if (response.error) {
                            if (isShouldRedirectForPayment) {
                                handleRedirectToPaymentPage(response.error);
                            }
                            trackAppyCntEvent('Subscription Error', trickFinalValue, eventParams).catch((e) =>
                                console.error(e),
                            );
                            setLoader(false);
                            return;
                        }

                        if (response.id) {
                            setPaymentParamsToSessionStorage({
                                url: paymentConfig?.recurly.uri ?? '',
                                selectedPlanId: selectedPlan.id,
                                subscriptionId: response.id,
                                attribution: JSON.stringify(attribution),
                            });

                            trackEvent(
                                EV[trickFinalValue.toUpperCase()],
                                undefined,
                                EV[trickFinalValue.toUpperCase()],
                                eventParams,
                            );
                            pixelTrackStandard(trickFinalValue, eventParams);

                            setSubscriptionId(response.id);
                            setIsOpenSignInPopUp(true);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        trackAppyCntEvent('Subscription Error', trickFinalValue, eventParams).catch((e) =>
                            console.error(e),
                        );
                        setError(e.toString());
                        setLoader(false);
                    })
                    .finally(() => {
                        setLoader(false);
                    });
            }
        });
    }, [applePay, subscriptionNoUserEmail]);

    useUpdateEffect(() => {
        const isAppleAuth = user?.providerId === 'apple.com';

        const appleToken = isAppleAuth ? user?.providerData?.uid ?? user?.uid : undefined;

        const sessionPaymentParams = getPaymentParamsFromSessionStorage([
            'url',
            'subscriptionId',
            'selectedPlanId',
            'attribution',
        ]);

        const currentSubscriptionId = sessionPaymentParams.subscriptionId ?? subscriptionId;

        if (activateSubscriptionConfig && user && isAppleAuth) {
            setLoader(true);

            activateSubscriptionRecurly({
                url: sessionPaymentParams.url ?? paymentConfig?.recurly.uri ?? '',
                email,
                firebase_id: undefined,
                apple_token: appleToken,
                account_code: activateSubscriptionConfig.account_code,
            })
                .then((response) => {
                    if (response.error) {
                        console.error(response.error);
                        setError(response.error);
                    } else if (!response?.success) {
                        setError('The subscription has been tied to another provider. Try another option');
                    } else {
                        setStep(QUIZ_STEPS_MAP.MANUAL);
                    }
                    if (response.error) {
                        console.error(error);
                        setLoader(false);
                    } else {
                        setStep(QUIZ_STEPS_MAP.MANUAL);
                    }
                })
                .catch((e) => {
                    console.error('[ApplePay subscriptionActivate error]: ', e);
                    setError(e.toString());
                })
                .finally(() => {
                    setPaymentParamsToSessionStorage({
                        [PRELOADER_SIGN_IN_KEY]: '',
                    });
                    setLoader(false);
                });
        } else if (user && currentSubscriptionId !== '' && isAppleAuth) {
            setLoader(true);

            createSubscriptionRecurly({
                url: sessionPaymentParams.url ?? paymentConfig?.recurly.uri ?? '',
                plan_id: sessionPaymentParams.selectedPlanId ?? selectedPlan?.id ?? '',
                subscription_id: currentSubscriptionId,
                email: email ?? '',
                firebase_id: undefined,
                apple_token: appleToken,
                attribution: JSON.parse(sessionPaymentParams.attribution) ?? attribution,
            })
                .then(() => {
                    setPaymentParamsToSessionStorage({
                        subscriptionId: '',
                        [QUIZ_STEP_KEY]: '',
                    });
                    setSubscriptionId('');
                    setStep(QUIZ_STEPS_MAP.MANUAL);
                })
                .catch((e) => {
                    console.error('[ApplePay subscriptionFinalize error]: ', e);
                    setError(e.toString());
                })
                .finally(() => {
                    setPaymentParamsToSessionStorage({
                        [PRELOADER_SIGN_IN_KEY]: '',
                    });
                    setLoader(false);
                    removeRedirectParamsFromQuery();
                });
        }
    }, [user, subscriptionId]);

    function handleClickByApplePayButton(email) {
        applePay?.begin();
        setSubscriptionNoUserEmail(email ?? paymentAppleEmail);
    }

    const value = {
        isLoader,
        isReadyAppleButton,
        handleClickButton: handleClickByApplePayButton,
        errorApplePay: error,
        setError,
    };

    return <ContextApplePayMethod.Provider value={value}>{children}</ContextApplePayMethod.Provider>;
}

export default ApplePayProvider;
