import { useTranslation } from 'react-i18next';

import useCurrency from '../../../../providers/CurrencyContext/CurrencyContext.hooks';
import currencyConverter from '../../../../services/currencyConverter';

import { convertedDate, getValidWord } from './Subscription.helpers';
import * as S from './Subscription.styles';

function Subscription({
    onUnSubscribe,
    name,
    price,
    next,
    last,
    code,
    term,
    currency,
    isUnSubscribe,
    loading,
    className,
}) {
    const { t } = useTranslation();
    const { currencyRate } = useCurrency();
    const termValue = term?.split(' ')[0] ?? '';

    let termType = term?.split(' ')[1] ?? '';
    if (termType.includes('Day')) {
        termType = 'day';
    }
    if (termType.includes('Month')) {
        termType = 'month';
    }
    if (termType.includes('Year')) {
        termType = 'year';
    }

    const instatrackPlans = {
        instatrack_monthly_15: t('subscriptions.plans.instatrack_monthly_15'),
        instatrack_one_month: t('subscriptions.plans.instatrack_one_month'),
        instatrack_one_month_sale: t('subscriptions.plans.instatrack_one_month_sale'),
        instatrack_2months: t('subscriptions.plans.instatrack_2months'),
        instatrack_yearly40: t('subscriptions.plans.instatrack_yearly40'),
    };

    return (
        <S.Component isUnSubscribe={isUnSubscribe} className={className}>
            <S.Row>
                <S.Value isUnSubscribe={isUnSubscribe} style={{ whiteSpace: 'nowrap' }}>
                    {instatrackPlans[code] || name}
                </S.Value>
                <S.GroupPrice>
                    <S.Price isUnSubscribe={isUnSubscribe}>{currencyConverter(price, currencyRate)}</S.Price>{' '}
                    <S.Value isUnSubscribe={isUnSubscribe}>
                        {currencyRate.currencyCode}/{`${termValue} ${getValidWord(termValue, termType, t)}`}
                    </S.Value>
                </S.GroupPrice>
            </S.Row>
            <S.Separator />
            <S.Row className="bottom-row">
                <S.Group>
                    <S.Label>
                        {isUnSubscribe ? t('subscriptions.subcriptionCanceled') : t('subscriptions.nextPayment')}
                    </S.Label>
                    <S.Value className="date-value">
                        {isUnSubscribe ? convertedDate(last, t) : convertedDate(next, t)}
                    </S.Value>
                </S.Group>
                {!isUnSubscribe && (
                    <S.UnsubscribeButton onClick={onUnSubscribe} loading={loading}>
                        {t('subscriptions.apiPath')}
                    </S.UnsubscribeButton>
                )}
            </S.Row>
        </S.Component>
    );
}

export default Subscription;
