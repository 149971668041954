import { createContext, useEffect, useState } from 'react';

import { AmplitudeProvider, useAmplitude } from 'react-amplitude-hooks';

import { trackPage } from '../../services/requests';
import { filterFalsyValues } from '../../utils/common';
import useAttribution from '../../hooks/loggerHooks/useAttribution';
import { getUID } from '../../utils/loggerUtils';
import useAppContext from '../AppContext/AppContext.hooks';

const AMPLITUDE_KEY = '9abf0173af0c5d39d4a7fd489cdf8e3f';

export const AmplitudeLogging = createContext(null);

function AmpInstance({ children }) {
    const { attribution } = useAttribution();
    const { appDomain } = useAppContext();

    const { logEvent } = useAmplitude();

    const trackAmplitudeEvent = (event, eventType, options) => {
        const finalOptions = options ?? {};
        const finalAttribution = attribution ?? {};

        logEvent(
            event,
            filterFalsyValues({
                campaignId: finalAttribution.campaign_id ?? '',
                campaign: finalAttribution.campaign_name ?? '',
                project: appDomain.appName,
                userAgent: window.navigator.userAgent,
                event: event,
                event_source_url: finalAttribution.event_source_url,
                ip: finalAttribution.client_ip_address,
                country: finalAttribution.country_code,
                eventCategory: eventType ? eventType : event,
                config: finalAttribution.config ?? '',
                uid: getUID(),
                ...finalOptions,
            }),
        );
    };

    const trackAppyCntEvent = async (event, eventCategory, options) => {
        const finalAttribution = attribution;
        const finalOptions = options ?? {};

        let finalParams = {
            campaignId: finalAttribution.campaign_id ?? '',
            campaign: finalAttribution.campaign_name ?? '',
            project: appDomain.appName,
            userAgent: window.navigator.userAgent,
            event: event,
            ip: finalAttribution.client_ip_address,
            country: finalAttribution.country_code,
            event_source_url: finalAttribution.event_source_url,
            eventCategory: eventCategory ?? event,
            config: finalAttribution.config ?? '',
            adset_id: finalAttribution.adset_id ?? '',
            adset_name: finalAttribution.adset_name ?? '',
            ad_id: finalAttribution.ad_id ?? '',
            ad_name: finalAttribution.ad_name ?? '',
            media_source: finalAttribution.media_source ?? '',
            fbc: finalAttribution.fbc ?? '',
            fbp: finalAttribution.fbp ?? '',
            client_user_agent: navigator.userAgent,
            uid: getUID(),
            ...finalOptions,
        };

        const filteredParams = filterFalsyValues(finalParams);

        trackPage(event, eventCategory, filteredParams).catch(console.error);
    };

    const value = {
        trackAmplitudeEvent,
        trackAppyCntEvent,
    };

    return <AmplitudeLogging.Provider value={value}>{children}</AmplitudeLogging.Provider>;
}

function LoggerProvider({ children }) {
    const [ampInstance, setAmpInstance] = useState(null);

    useEffect(() => {
        const amplitude = require('amplitude-js').default;
        const amplitudeInstance = amplitude.getInstance();

        amplitudeInstance.init(AMPLITUDE_KEY);

        if (amplitudeInstance.isNewSession()) {
            const visitor = new amplitudeInstance.Identify();

            amplitudeInstance.identify(visitor);
        }
        setAmpInstance(amplitudeInstance);
    }, []);

    return (
        <AmplitudeProvider amplitudeInstance={ampInstance ?? {}} apiKey={AMPLITUDE_KEY}>
            <AmpInstance>{children}</AmpInstance>
        </AmplitudeProvider>
    );
}

export default LoggerProvider;
