import { useEffect } from 'react';

import useAppContext from '../../providers/AppContext/AppContext.hooks';

const useDocumentTitle = () => {
    const props = useAppContext();
    useEffect(() => {
        if (props?.appDomain) {
            switch (true) {
                case (props.appDomain.isFollowersreport): {
                    document.title = 'SocialSpy: Profile Analyzer';
                    break;
                }
                case (props.appDomain.isBillingtracker): {
                    document.title = 'Billing Tracker';
                    break;
                }
                default: {
                    document.title = 'Profile Report+';
                }
            }
        }
    }, [props?.appDomain]);
};

export default useDocumentTitle;
