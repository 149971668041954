import { useTranslation } from 'react-i18next';

import { pixelTrackStandard } from '../../../../services/analytics/fPixel';
import { PixelEvents } from '../../../../services/analytics/analyticEvents/pixelStandardEvents';
import { EV } from '../../../../services/analytics/analyticEvents/appyCntEvents';
import { useTrackEvent } from '../../../../hooks/loggerHooks/useTrackEvent';
import useAppContext from '../../../../providers/AppContext/AppContext.hooks';

import { getFooterLinks } from './Footer.constants';
import * as S from './Footer.styles';

function Footer() {
    const { t } = useTranslation();

    const trackEvent = useTrackEvent();
    // @ts-ignore
    const { appDomain } = useAppContext();

    let company = 'ROSIANE DA ROSA ANDRADES, Rua Nove 1424, Jaboatão Dos Guararapes, Pernambuco 54240-080, Brazil';

    // switch (true) {
    //     // case appDomain.isProfilereport:
    //     //     company = 'SocialSpy';
    //     //     break;
    //     case appDomain.isFollowersreport:
    //         company = 'Dinh Cong Thanh, 65 Pham Ngoc Thach, Phuong Vo Thi Sau, Quan 3 Ho Chi Minh, 70000, Viet Nam';
    //         break;
    // }

    const appName = appDomain.isFollowersreport ? 'SocialSpy: Profile Analyzer' : 'Profile Report+';

    const handleTrack = () => {
        trackEvent(EV.CONTACT);
        pixelTrackStandard(PixelEvents.Contact);
    };

    return (
        <S.Footer>
            <S.FooterInner>
                <S.CopyRight>{t('landing.footer.copyright', { appName: appName })}</S.CopyRight>
                <S.Separator />
                <S.FooterLinks>
                    {getFooterLinks(t, appDomain.email, appDomain.domain).map((item, index) => (
                        <S.FooterItemLink key={index}>
                            <a
                                id={`FooterItemLink${index}`}
                                href={item.url}
                                target={item.target}
                                onClick={() => {
                                    if (index === getFooterLinks(t).length - 1) {
                                        handleTrack();
                                    }
                                }}
                            >
                                {item.label}
                            </a>
                        </S.FooterItemLink>
                    ))}
                </S.FooterLinks>
            </S.FooterInner>
            <S.Company>{ company }</S.Company>
        </S.Footer>
    );
}

export default Footer;
