import { useContext } from 'react';

import { ContextApplePayMethod } from './ApplePayContext';

function useApplePay() {
    const context = useContext(ContextApplePayMethod);

    if (!context) {
        throw new Error('use only inside the provider');
    }

    return {
        error: context.errorApplePay,
        setError: context.setError,
        isLoading: context.isLoader,
        isReadyAppleButton: context.isReadyAppleButton,
        handleClickButton: context.handleClickButton,
    };
}

export default useApplePay;
