import { useContext } from 'react';

import { ContextPayByCardMethod } from './PayByCardContext';

function usePayByCard() {
    const context = useContext(ContextPayByCardMethod);

    if (!context) {
        throw new Error('use only inside the provider');
    }
    return {
        handleClickButton: context.handleClickButton,
        formRef: context.formRef,
        recurlySecureRef: context.recurlySecureRef,
        errorRef: context.errorRef,
        isLoading: context.isLoader,
        setError: context.setError,
        error: context.error,
    };
}

export default usePayByCard;
