import styled from 'styled-components';

export const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;

    @media (max-width: 1024px) {
        display: block;
        position: relative;
        object-position: top center;
        object-fit: contain;
        background-repeat: no-repeat;
        margin: initial;
        width: 100%;
        min-height: 360px;
        max-height: 414px;
        background-color: var(--bg-color);
    }
`;

export const MainBackgroundImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    pointer-events: none;
    user-select: none;

    @media (max-width: 1024px) {
        display: none;
    }
`;

export const PeopleImage = styled(MainBackgroundImage)`
    display: block !important;
    z-index: 2;

    width: 88%;
    margin-left: auto;

    @media (max-width: 1024px) {
        position: absolute;
        object-position: top center;
        object-fit: contain;
        margin: initial;
        width: 100%;
    }
`;

export const Inner = styled.div`
    padding: 40px 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    height: 100%;
`;

export const Header = styled.header``;

export const Component = styled.div`
    position: relative;
    height: 100%;
    background-color: var(--bg-color);

    @media (max-width: 1024px) {
        ${Header} {
            display: flex;
            justify-content: center;
            svg {
                display: none;
            }
        }

        ${Inner} {
            max-width: 414px;
            margin: -20px auto 0;
            justify-content: flex-end;
            height: auto;
            padding: 0 0 40px;
        }
    }

    @media screen and (max-height: 700px) {
        @media screen and (min-width: 1024px) {
            ${Inner} {
                height: auto;
            }
        }
    }

    @media screen and (max-height: 650px) {
        @media screen and (max-width: 440px) {
            ${Inner} {
                height: auto;
            }
        }
    }
`;
