import { useTranslation } from 'react-i18next';

import useAppContext from '../../../../providers/AppContext/AppContext.hooks';

import * as S from './Main.styles';

function Main({ onClick }) {
    const { t } = useTranslation();
    // @ts-ignore
    const { appDomain } = useAppContext();
    let title = t('landing.title');

    switch (true) {
        // case appDomain.isFollowersreport:
        //     title = 'PixelGuru: Paint by Numbers';
        //     break;
        // case appDomain.isProfilereport:
        //     title = 'SocialSpy';
        //     break;
        default:
            title = 'Profile Report+';
            break;
    }

    return (
        <S.Content>
            <S.Title>
                {/*{appDomain.appName}: <br />*/}
                {title}
            </S.Title>
            <S.SubTitle>{t('landing.subTitle')}</S.SubTitle>
            <S.StyledButton id="LandingBtn" withIcon onClick={onClick}>
                {t('landing.buttonTitle')}
            </S.StyledButton>
        </S.Content>
    );
}

export default Main;
