export const QUIZ_STEPS_MAP = {
    LANDING: 'LANDING',
    INTRODUCTION: 'INTRODUCTION',
    ANALYSIS: 'ANALYSIS',
    PREVIEW: 'PREVIEW',
    SUBSCRIPTION: 'SUBSCRIPTION',
    SIGN_IN: 'SIGN_IN',
    MANUAL: 'MANUAL',
};

export const QUIZ_STEP_KEY = 'QUIZ_STEP';
export const PRELOADER_SIGN_IN_KEY = 'PRELOADER_SIGN_IN';
