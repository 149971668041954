import Phone1 from '../../../../assets/images/unsubscribe/phone1.png';
import Phone2 from '../../../../assets/images/unsubscribe/phone2.png';
import Phone3 from '../../../../assets/images/unsubscribe/phone3.png';
import Phone4 from '../../../../assets/images/unsubscribe/phone4.png';

import { ImageWrapper } from './Manual.styles';
import DownloadApp from './components/DownloadApp';

export function getStepsData(t) {
    return [
        {
            title: t('unSubscribe.manual.steps.0'),
            children: <DownloadApp />,
        },
        {
            title: t('unSubscribe.manual.steps.1'),
            children: (
                <ImageWrapper>
                    <img src={Phone1} alt="app-step-1" className="phone-image-1" />
                </ImageWrapper>
            ),
        },
        {
            title: t('unSubscribe.manual.steps.2'),
            children: (
                <ImageWrapper>
                    <img src={Phone2} alt="app-step-2" className="phone-image-2" />
                </ImageWrapper>
            ),
        },
        {
            title: t('unSubscribe.manual.steps.3'),
            children: (
                <ImageWrapper>
                    <img src={Phone3} alt="app-step-3" className="phone-image-3" />
                </ImageWrapper>
            ),
        },
        {
            title: t('unSubscribe.manual.steps.4'),
            children: (
                <ImageWrapper>
                    <img src={Phone4} alt="app-step-4" className="phone-image-4" />
                </ImageWrapper>
            ),
        },
    ];
}
