import cookie from 'js-cookie';

const uuid = () =>
    'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

export const getFacebookClickParameter = () => {
    try {
        const fbclidAsSearchParam = new URLSearchParams(window.location.search).getAll('fbclid');

        let _fbp = '';
        let _fbc = '';
        let fbclid = null;

        const cookies = cookie.get(document.cookie);

        if (cookies) {
            _fbp = cookie.get('_fbp');
            _fbc = cookie.get('_fbc');
            fbclid = cookie.get('fbclid');
            if (!fbclid || fbclid === '') {
                fbclid = uuid();
            }
        }

        const facebookLidParameter = fbclid ?? (fbclidAsSearchParam.length !== 0 ? fbclidAsSearchParam : '');

        const time = new Date().getTime();

        if (!_fbc || facebookLidParameter) {
            if (facebookLidParameter === '') {
                _fbc = '';
            } else {
                _fbc = `fb.1.${time}.${facebookLidParameter}`;
            }
        }

        if (!_fbp) {
            _fbp = 'fb.1.' + time + '.' + Math.random().toString().slice(2, 12);
        }

        return { _fbc, _fbp };
    } catch (error) {
        console.error('Could not properly generate _fbc, _fbp', error);
    }
    return { _fbc: '', _fbp: '' };
};

/**
 * Generate UID for each user
 * Needed for analytics
 */
export function getUID() {
    let cookieUID = cookie.get('_APP_UID');
    if (cookieUID === '') {
        cookieUID = Math.random().toString().slice(2, 11);
        cookie.set('_APP_UID', cookieUID, 180);
    }
    return cookieUID;
}
