import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { EMAIL_REGEXP } from '../../../../constants/common';
import { cancelSubscriptions } from '../../../../services/requests';
import { ErrorMessage, Input, InputWrapper } from '../../../../components/common';
import { useModal } from '../../../../providers/ModalProvider';
import CheckEmailModal from '../../../../components/modals/CheckEmailModal';
import HeaderImage from '../../../../components/HeaderImage';
import useAppContext from '../../../../providers/AppContext/AppContext.hooks';

import S, { Inner, StyledButton } from './Form.styles';

function Form({ onNextStep }) {
    const [value, setValue] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const openModal = useModal();
    const { t } = useTranslation();
    const { appDomain } = useAppContext();

    function onChange(event) {
        setValue(event.target.value);
    }

    function handleClick() {
        if (loading) return;

        if (EMAIL_REGEXP.test(value)) {
            setError(null);
            setLoading(true);

            cancelSubscriptions({
                email: value,
                APP_URL: appDomain.appUrl.url,
                code: appDomain.appUrl.apiPath,
            })
                .then(() => {
                    openModal(CheckEmailModal, {
                        onClick: onNextStep,
                        text: t('unSubscribe.form.successEmailMessage'),
                    });
                })
                .catch((error) => {
                    console.error(error);

                    const errorMessage = typeof error === 'string' ? error : error.error ?? error.message;

                    setError(typeof errorMessage === 'string' ? errorMessage : t('baseErrorMessage'));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    return (
        <S.Component>
            <S.Container>
                <S.Wrapper>
                    <HeaderImage />
                    <S.Content>
                        <Inner>
                            <S.Title> {t('unSubscribe.form.title')}</S.Title>
                            <S.SubTitle className="mt-20">{t('unSubscribe.form.subTitle')}</S.SubTitle>

                            <InputWrapper>
                                <Input
                                    placeholder={t('unSubscribe.form.inputPlaceholder')}
                                    value={value}
                                    onChange={onChange}
                                />
                            </InputWrapper>
                            <StyledButton loading={loading} onClick={handleClick} disabled={!EMAIL_REGEXP.test(value)}>
                                {t('unSubscribe.form.buttonTitle')}
                            </StyledButton>
                            {error && <ErrorMessage error={!!error}>{error}</ErrorMessage>}

                            <S.Bottom>
                                <S.BottomText
                                    dangerouslySetInnerHTML={{
                                        __html: t('supportText').replace(
                                            '{{}}',
                                            `<a href="${appDomain.email.url}">${t('supportLinkText')}</a>`,
                                        ),
                                    }}
                                />
                            </S.Bottom>
                        </Inner>
                    </S.Content>
                </S.Wrapper>
            </S.Container>
        </S.Component>
    );
}

export default Form;
