import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const styles = css`
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--textSecondary-color);
`;

export const FooterInner = styled.div`
    display: flex;
`;

export const FooterLinks = styled.ul`
    display: flex;
`;

export const Separator = styled.div`
    margin: 0 30px;
    width: 1px;
    height: 20px;
    background: var(--gray50-color);
`;

export const CopyRight = styled.span`
    ${styles}
`;

export const Company = styled.div`
    ${styles};
    text-align: left;
    padding-top: 15px;
`;

export const NewLine = styled.div`
    flex-basis: 100%;
    height: 20px;
    background-color: red;
`;

export const FooterItemLink = styled.li`
    margin-right: 30px;

    &:last-child {
        margin-right: 0;
    }

    a {
        ${styles};
        text-decoration: underline;
    }
`;

export const FooterLink = styled(Link)``;

export const Footer = styled.footer`
    margin: 40px 0 0;

    @media (max-width: 1024px) {
        margin: 0;

        ${FooterInner} {
            flex-direction: column;
        }

        ${CopyRight} {
            order: 5;
            margin-top: 30px;
        }

        ${Separator} {
            order: -1;
            width: 100%;
            height: 1px;
            margin: 40px 0;
        }

        ${FooterItemLink} {
            text-align: center;
            margin: 15px 0 0 0;

            &:first-child {
                margin-top: 0;
            }
        }

        ${FooterLinks} {
            flex-direction: column;
        }
    }
`;
