import styled, { css } from 'styled-components';

import Button from '../../../../components/Button';

export const Separator = styled.div`
    margin: 24px 0;
    width: 100%;
    background: #e8e8e8;
    height: 1px;
`;

export const UnsubscribeButton = styled(Button)`
    max-width: 210px;
    padding-top: 17px;
    padding-bottom: 17px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    background: var(--error-color);
`;

export const Label = styled.span`
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: var(--textSecondary-color);
`;

export const Value = styled.span`
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--textPrimary-color);

    ${(props) =>
        props.isUnSubscribe &&
        css`
            color: var(--textSecondary-color);
        `}
`;

export const GroupPrice = styled.div`
    margin-left: 10px;
    text-align: end;
`;

export const Price = styled.span`
    font-weight: 600;
    font-size: 24px;
    line-height: 32.78px;
    color: var(--textPrimary-color);
    ${(props) =>
        props.isUnSubscribe &&
        css`
            color: var(--textSecondary-color);
        `}
`;

export const Group = styled.div`
    display: flex;
    flex-direction: column;

    ${Value} {
        margin-top: 4px;
    }
`;

export const Component = styled.div`
    padding: 24px;
    background: #ffffff;
    box-shadow: 0 8px 16px rgba(145, 81, 72, 0.05);
    border-radius: 16px;

    ${(props) =>
        props.isUnSubscribe &&
        css`
            background: transparent;
            box-shadow: none;
            border: 1px solid rgba(189, 185, 182, 0.4);

            .date-value {
                color: #282624;
            }
        `}
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        &.bottom-row {
            align-items: flex-start;
            flex-direction: column;
        }

        ${UnsubscribeButton} {
            margin-top: 24px;
        }
    }
`;
