export function getActiveSubscriptionConfig() {
    const pathAsArray = window.location.pathname.split('/').filter(Boolean);

    if (pathAsArray.includes('activate-me') || pathAsArray.includes('activate')) {
        return {
            account_code: pathAsArray[pathAsArray.length - 1],
        };
    } else {
        return null;
    }
}

export function setPaymentParamsToSessionStorage(params) {
    Object.keys(params).forEach((key) => {
        const value = params[key];
        window.sessionStorage.setItem(`__${key}`, value);
    });
}

export function getPaymentParamsFromSessionStorage(params) {
    const result = {};

    params.forEach((key) => {
        const value = window.sessionStorage.getItem('__' + key);

        if (value) {
            result[key] = value;
        }
    });

    return result;
}
